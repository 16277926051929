import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { CommonService } from '../core/common.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  public btn: boolean = true;
  packages: any;
  dollar: any = [75 , 0];
  yrAmt: any = [199 , 0];
  toolTipID: any;

  constructor(public authService: AuthService, public commonService: CommonService) { }

  plan(value){
    if(value == 'mo'){
      this.btn = true;
      this.dollar[0] = 75;
      this.dollar[1] = '00';
     
      this.yrAmt[0] = 199;
      this.yrAmt[1] = '00';
    }
    else{
      this.btn = false;
      let str = 750;
      let str1 = str / 12;
      this.dollar = str1.toFixed(2).toString().split('.');

      //  this is for split Pro amount to two veriables
      let str2 = 1899;
      let str3 = str2 / 12;
      this.yrAmt = str3.toFixed(2).toString().split('.');
    }


  }

  toolTip(a){
    this.toolTipID = a;
  }

  ngOnInit() {
    
    this.dollar[0] = 75;
      this.dollar[1] = '00';
     
      this.yrAmt[0] = 199;
      this.yrAmt[1] = '00';
      
      
  }

}
