import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  constructor(private http: HttpClient) { }

  // create package during package creation in Firebase at same time
  cretePackage(body) {
    // console.log(body);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/create_package', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // register user id and referral link in db
  registerUser(userId, email, username, name) {
    const ref_key = (window.localStorage.getItem('referralID')) ? window.localStorage.getItem('referralID') : '';
    const body = { userId, username, email, name, ref_key };
    // console.log(body);
    // console.log(environment.apiURL + '/add_referral');

    // return this.http.post(environment.apiURL + '/add_referral', JSON.stringify(body), {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
    // }).subscribe(res => {
    // });

    // const formData = new FormData();
    // formData.append('userId', userId);
    // formData.append('ref_key', '123');

    const bodyString = JSON.stringify(body); // Stringify payload
    const headers    = new HttpHeaders({ 'Content-Type': 'application/json' });

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/add_referral', bodyString, { headers })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // get specific user referral details
  getUserReferralData(uid) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiURL + '/referral/user_detail/?userid=' + uid, {
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // admin dashbaord data
  getAdminDashBoardData() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + '/referral/admin_detail', {
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // send money withdrawal request to admin
  moneyWithdrawalRequest(uid, formData) {
    const body = {
      user_id: uid,
      usdt_wallet: formData.walletID,
      amount: formData.amount
    };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/withdraw_request', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // transaction history of user
  getTransactionHistory(uid, offset) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + '/referral/withdrawal_detail/?userid=' + uid + '&page=' + offset, {
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
  // admin transaction history
  getAdminTransactionHistory(offset) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + '/referral/withdrawl_admin_detail' + '?page=' + offset, {
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }

  // update transaction status by admin
  updateCurrentTransaction(transaction_id, status) {
    const body = { withdraw_id: transaction_id, status };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/withdrawl_status_update', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // generate Report
  generateReport(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/withdrawl_admin_detail1', JSON.stringify(data), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }


  // change transaction amount
  changeTransactionAmount(id, val) {
    const body = { withdraw_id: id, amount: val };
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiURL + '/referral/withdrawl_amount_update', JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
      })
        .subscribe(res => {
          resolve(res);
          // console.log(JSON.stringify(res));
        }, (err) => {
          reject(err);
        }, () => {
        });
    });
  }
}
