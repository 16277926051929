import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  constructor(private router: Router, private translate: TranslateService) { }

  ngOnInit() {
  }

  openEnglish() {
    this.translate.use('en');
    this.router.navigate(['/login']);
  }

  openArabic() {
    this.translate.use('ar');
    this.router.navigate(['/login']);
  }
}
