import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Globals } from './core/globals';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { HomeComponent } from './home/home.component';

import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { AuthGuardService } from './guards/auth-guard.service';
import { AuthService } from './core/auth.service';
import { CommonService } from './core/common.service';
import { GroupService } from './core/group.service';
import {  NgxSocialButtonModule,  SocialServiceConfig} from "ngx-social-button";


import { ChatComponent } from './chat/chat.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Filters
import { FilterbyPipe } from './directives/filterby.pipe';
import { OrderModule } from 'ngx-order-pipe';


import { ToastrModule } from 'ngx-toastr';
import { PriceactionComponent } from './priceaction/priceaction.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeadComponent } from './design/head/head.component';
import { FootComponent } from './design/foot/foot.component';
import { LeftsidebarComponent } from './components/leftsidebar/leftsidebar.component';
import { RsiscannerComponent } from './rsiscanner/rsiscanner.component';
import { VolumescannerComponent } from './volumescanner/volumescanner.component';
import { FibolevelsComponent } from './fibolevels/fibolevels.component';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PackageMangerComponent } from './package-manger/package-manger.component';

import { CommonModule } from '@angular/common';
import { MatCheckboxModule, MatButtonModule, MatDialogModule, MatSlideToggleModule } from '@angular/material';
// import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { FbTimeFormatPipe } from './directives/fbTimeFormat.pipe';
import { ScrollEventModule } from 'ngx-scroll-event';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ReferralComponent } from './referral/referral.component';
import { LanguageComponent } from './language/language.component';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MalevelsComponent } from './malevels/malevels.component';
import { BlogComponent } from './blog/blog.component';
// import { BlogpageComponent } from './blogpage/blogpage.component';

import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { BlogEditComponent } from './blog/blog-edit/blog-edit.component';
// import { BlogListingComponent } from './blog/blog-listing/blog-listing.component';
import { DeletePopupComponent } from './blog/delete-popup/delete-popup.component';


import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QuillModule } from 'ngx-quill';
import { AngularFirestore } from '@angular/fire/firestore';



import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { MacrossoverComponent } from './macrossover/macrossover.component';
import { PaymentHistoryComponent } from './admin/payment-history/payment-history.component';
import { SettingsComponent } from './settings/settings.component';
import { ExchangesComponent } from './exchanges/exchanges.component';
import { ScannersComponent } from './marketscanners/scanners.component';

import { ScannerPopup } from './components/scannerpopup/scannerpopup.component';


import { StaterComponent } from './subscription/stater/stater.component';
import { ProComponent } from './subscription/pro/pro.component';
import { CreditCardComponent } from './subscription/credit-card/credit-card.component';
import { PaypalComponent } from './subscription/paypal/paypal.component';
import { CryptoComponent } from './subscription/crypto/crypto.component';
import { WalletComponent } from './wallet/wallet.component';
import { AssetsComponent } from './wallet/assets/assets.component';
import { PositionsComponent } from './wallet/positions/positions.component';
import { OrdersComponent } from './wallet/orders/orders.component';
import { TradeHistoryComponent } from './wallet/trade-history/trade-history.component';
import { PricingComponent } from './pricing/pricing.component';
import { EllipsisModule } from 'ngx-ellipsis';


import {TimeAgoPipe} from 'time-ago-pipe';



// rxjs
// import 'rxjs/add/operator/map';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LanguageComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    ChatComponent,
    PriceactionComponent,
    HeaderComponent,
    FooterComponent,
    LeftsidebarComponent,
    RsiscannerComponent,
    FibolevelsComponent,
    VolumescannerComponent,
    FilterbyPipe,
    SubscriptionComponent,
    PackageMangerComponent,
    ManageSubscriptionComponent,
    FbTimeFormatPipe,
    ReferralComponent,
    MalevelsComponent,
    MacrossoverComponent,
    BlogComponent,
    // BlogpageComponent,
    BlogDetailComponent,
    BlogEditComponent,
    // BlogListingComponent,
    DeletePopupComponent,

    PaymentHistoryComponent,
    SafeHtmlPipe,
    HomeComponent,
    HeadComponent,
    FootComponent,
    SettingsComponent,
    ExchangesComponent,
    ScannersComponent,
    ScannerPopup,
    StaterComponent,
    ProComponent,
    CreditCardComponent,
    PaypalComponent,
    CryptoComponent,
    WalletComponent,
    AssetsComponent,
    PositionsComponent,
    OrdersComponent,
    TradeHistoryComponent,
    PricingComponent,
    TimeAgoPipe,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    EllipsisModule,
    HttpClientModule,
    NgxSocialButtonModule,
    JwSocialButtonsModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OrderModule,
    NgbModule.forRoot(),
    QuillModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    // MatPasswordStrengthComponent,
    ScrollEventModule,
    Nl2BrPipeModule,
    PickerModule,
    LoadingBarHttpClientModule
  ],
  entryComponents: [ManageSubscriptionComponent],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    // MatPasswordStrengthComponent,
  ],
  providers: [AuthGuardService, AngularFirestore, AuthService, CommonService, GroupService,
    {
      provide: SocialServiceConfig,
      useFactory: getAuthServiceConfigs
  },
    Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function getAuthServiceConfigs() {
  let config = new SocialServiceConfig()
      .addFacebook('376233219702383');
      return config;
}
