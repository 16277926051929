import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { CommonService } from '../../core/common.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { PaymentService } from '../../core/payment.service';
declare let paypal: any;
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  public open:boolean = false;
  public createPaymentsRef;
  constructor(public authService: AuthService, 
    public commonService: CommonService, 
    public paymentService: PaymentService, 
    public fdb: AngularFireDatabase ){}
  
  
  addScript: boolean = false;
  paypalLoad: boolean = true;
  paypalConfig = {
    env: 'sandbox',
    client: {
      sandbox: 'AUz_3--Box0bZGAoGzEkmjgqG3huLt2rmKXffHKVViBo16t-uVTpNkt1EHZ3CixXICPGL-817teZ_oj9',
      production: '<your-production-key here>'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: 123, currency: 'USD' } }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        //console.log(payment, "Response Caught");
        this.doPaypalPayment();
      })
    },
    style:{
      color: "blue",
      shape: "rect",
      size: "responsive",
      tagline: "false"

    }
  };
  ngAfterViewChecked(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = false;
      })
    }
  }
  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');    
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
  ngOnInit() {
    this.createPaymentsRef = this.fdb.database.ref('/payments');
    this.authService.paymtName = 'PayPal';
  }
  async doPaypalPayment(){
      const user = await this.authService.getUser();
      this.paymentService.createPaymentRequest(user.uid, this.commonService.subPayment, user.email, 'PayPal',
        this.commonService.duration, this.commonService.currentPckName)
        .subscribe(res => {
          const json = JSON.parse(res);
          this.createPaymentsRef.child(user.uid).child(json.transactionId).set({
            statusUrl: json.statusUrl,
            packageName: this.commonService.currentPckName,
            packageId: this.commonService.currentPckId,
            duration: this.commonService.duration,
            cratedOn: this.authService.timestamp
          });
        }, error => {
            console.log(error);
        });
  }
}
