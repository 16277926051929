import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../core/common.service';
import { PaymentService } from '../core/payment.service';
import { AuthService } from '../core/auth.service';
import { PaymentInfo, Payment } from '../models/paymentInfo';
import { Subscription } from 'rxjs';
import { UserService } from '../core/user.service';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {

  public dropbox = false;
  public selectsubscribe = 'month';
  displayPayment = 'none';
  displayQrCode = 'none';
  priceToPay = 29;
  subscriptionType = 'Starter';
  payBtnDisabled = false;
  paymentInfo = new PaymentInfo();
  stepOne: NgbModalRef;
  userPayments: Array<Payment> = [];
  paymentRef: Subscription;
  showThis = false;

  constructor(private router: Router, public commonservice: CommonService, public auth: AuthService,
              private modalService: NgbModal, public paymentService: PaymentService, private userService: UserService) {
    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    const $this = this;
    // this.paymentRef = this.auth.payments$.subscribe(
    //   (payment) => {
    //     $this.userPayments.push(payment);
    //   }
    // );

    this.userService.showThis.subscribe(state => {
      this.showThis = state;
    });
  }

  purchasepopup(content, price, type) {
    this.stepOne = this.modalService.open(content, { windowClass: 'stepone' });
    this.priceToPay = price;
    this.subscriptionType = type;
  }

  btcpopup() {
    if (this.dropbox) {
      this.dropbox = false;
    } else {
      this.dropbox = true;
    }
  }

  selectsubscribefn(type) {
    this.selectsubscribe = type;
  }

  startLoading() {
    // console.log('here');
    // this.progressRef.start();
  }

  async openQrCode(content) {
    // this.payBtnDisabled = true;
    // this.startLoading();
    // const user = await this.auth.getUser();
    // this.paymentService.createPaymentRequest(user.uid, this.priceToPay, user.email, 'LTCT', 'duration').subscribe(res => {
    //   const json = JSON.parse(res);
    //   this.paymentInfo.qrCode = json.qrCode;
    //   this.paymentInfo.statusUrl = json.statusUrl;
    //   this.paymentInfo.amount = json.amount;
    //   this.paymentInfo.cryptoAddress = json.cryptoAddress;
    //   this.paymentInfo.currency = json.currency;

    //   this.modalService.open(content, { windowClass: 'steptwo' });
    //   this.stepOne.close();
    // });
  }

  ngOnDestroy(): void {
    if (this.paymentRef) {
      this.paymentRef.unsubscribe();
    }
  }

  openDialog() {
    this.userService.setValue(true);
  }
}
