import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { CommonService } from '../core/common.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  errorMessage = '';
  loginProcess = false;
  public shwp = false;
  public loginsuccess = false;
  public loginerror = false;

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder,  public commanService: CommonService,
              private toastr: ToastrService, private translate: TranslateService, public fdb: AngularFireDatabase) {}

  ngOnInit() {
    this.commanService.footerShow = true;
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl(),
    });

    this.authService.getClosestLanguage();
  }

  async tryLogin(value) {
    console.log(this.loginForm);
    this.loginProcess = true;
    if (!this.isValidEmail(value.email)) {

      this.loginsuccess = false;
      this.loginerror = true;
      this.errorMessage = this.translate.instant('toastr.not_email');

      // this.toastr.error(this.translate.instant('toastr.not_email'), this.translate.instant('toastr.ops'), { progressBar: true });
      this.loginProcess = false;
      return;
    }

    const credential = await this.authService.login(value);

    if (credential && credential.user) {
      this.fdb.database.ref(`/users/${credential.user.uid}/language`).once('value')
        .then(async (result) => {
          const language = result.val();
          // console.log('current user language is ', language);

          this.translate.use(language);
          this.authService.currentLanguage$.next(language);
          localStorage.setItem('language', language);

          // this.toastr.success(this.translate.instant('toastr.login'),
          // this.translate.instant('toastr.success'), { progressBar: true });
          this.loginsuccess = true;
          this.loginerror = false;
          this.errorMessage = 'Logged In Successfully.';
          this.authService.user$.subscribe(loggedUser => {
            this.loginProcess = false;
            if (loggedUser && (loggedUser.roles.subscriber || loggedUser.roles.admin)) {
              this.router.navigate(['/chat']);
            } else {
              this.router.navigate(['/subscription']);
            }
          });
        });
    } else {
      this.loginForm.setValue({ email: value.email, password: '' });
      // this.toastr.error(this.translate.instant('toastr.wrong_email'), this.translate.instant('toastr.ops'), { progressBar: true });
      this.loginsuccess = false;
      this.loginerror = true;
      this.loginProcess = false;
      this.errorMessage = 'Invalid email or password.';

    }
  }

  isValidEmail(email: string): boolean {
    // tslint:disable-next-line: max-line-length
    const emailValidator = /^\w+[\w-+\.]*\@\w+([-\.]\w+)*\.[a-zA-Z]{2,}$/;;

    if (email.match(emailValidator)) {
      return true;
    } else {
      return false;
    }
  }
}
