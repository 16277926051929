import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GroupService } from '../core/group.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../core/user.service';

@Component({
  selector: 'app-package-manger',
  templateUrl: './package-manger.component.html',
  styleUrls: ['./package-manger.component.scss']
})
export class PackageMangerComponent implements OnInit {

  customForm: FormGroup;
  private submitted: any = false;
  private showLoginSpinner = false;
  GroupCategoryList: any = [];
  SubCategoryList: any = [];
  SubCategoryList2: any = [];
  catLength: any;
  obbj = {};
  btnInfo = {};
  topFeatured = {};

  generalList: any = [];
  groupList: any = [];

  GeneralCheckList = [
    {
      id: 1,
      groupname: 'Price Action',
      value: 'priceAction',
      status: false
    },
    {
      id: 2,
      groupname: 'RSI Scanner',
      value: 'RSIscanner',
      status: false

    },
    {
      id: 3,
      groupname: 'Volume Scanner',
      value: 'volumeScanner',
      status: false

    },
    {
      id: 4,
      groupname: 'Algo Trade Journal',
      value: 'algoTradeJournal',
      status: false
    }
  ];

  priceAction = false;
  RSIscanner = false;
  volumeScanner = false;
  algoTradeJournal = false;

  constructor(private formBuilder: FormBuilder,
              private groupService: GroupService,
              private toastr: ToastrService,
              private userService: UserService, ) { }

  ngOnInit() {
    this.customForm = this.formBuilder.group({
      // email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      // password: new FormControl('', Validators.compose([Validators.minLength(6), Validators.required])),

      package_name: new FormControl('', [Validators.compose([Validators.required])]),
      one_month_rate: new FormControl('', [Validators.compose([Validators.required])]),
      six_month_rate: new FormControl('', [Validators.compose([Validators.required])]),
      one_year_rate: new FormControl('', [Validators.compose([Validators.required])]),


      ft_l1_month: new FormControl('', [Validators.compose([Validators.required])]),
      ft_l1_halfY: new FormControl('', [Validators.compose([Validators.required])]),
      ft_l1_fullY: new FormControl('', [Validators.compose([Validators.required])]),

      ft_l2_month: new FormControl('', [Validators.compose([Validators.required])]),
      ft_l2_halfY: new FormControl('', [Validators.compose([Validators.required])]),
      ft_l2_fullY: new FormControl('', [Validators.compose([Validators.required])]),

      rc_l1_month: new FormControl('', [Validators.compose([Validators.required])]),
      rc_l1_halfY: new FormControl('', [Validators.compose([Validators.required])]),
      rc_l1_fullY: new FormControl('', [Validators.compose([Validators.required])]),

      rc_l2_month: new FormControl('', [Validators.compose([Validators.required])]),
      rc_l2_halfY: new FormControl('', [Validators.compose([Validators.required])]),
      rc_l2_fullY: new FormControl('', [Validators.compose([Validators.required])]),

      top_feature1: new FormControl(''),
      top_feature2: new FormControl(''),
      top_feature3: new FormControl(''),
      top_feature4: new FormControl(''),
      top_feature5: new FormControl(''),
      isFeatured: new FormControl(false),

      btn_color: new FormControl('', [Validators.compose([Validators.required])]),
      btn_url: new FormControl(''),
    });


    this.groupService.getCategory().snapshotChanges().subscribe(
      data => {
        this.GroupCategoryList = data;
        this.catLength = this.GroupCategoryList.length;
        this.GroupCategoryList.forEach(element => {
          // console.log(element.payload.val());
          this.SubCategoryList2[element.payload.val().name] = element.payload.val().groups;
          Object.keys(element.payload.val().groups).map(v => {
            // console.log(v,element.payload.val().groups[v]);
            this.SubCategoryList.push({ index: v, value: element.payload.val().groups[v] });
            this.initiateThis(v, element.payload.val().name, element.payload.val().groups[v]);
          });
        });
      }
    );
  }

  initiateThis(grpNO, mainCat, bigData) {
    if (bigData.isPaid === true) { return; }
    if (this.obbj.hasOwnProperty(mainCat)) {
      this.obbj = Object.assign({}, this.obbj, { [mainCat]: Object.assign({}, this.obbj[mainCat], { [grpNO]: bigData.groupname }) })
    } else {
      this.obbj = Object.assign({ [mainCat]: { [grpNO]: bigData.groupname, category: bigData.category } }, this.obbj);
    }
  }

  get f() {
    return this.customForm.controls;
  }

  generalListChange(ev) {
    const idx = this.GeneralCheckList.findIndex(v => v.value === ev.target.value);
    this.GeneralCheckList[idx].status = ev.target.checked;
  }

  // check box 
  groupListChange(value, category, type, group) {
    if (this.obbj.hasOwnProperty(type)) {
      const idd = Object.keys(this.obbj[type]).findIndex(v1 => v1 === group);
      if (idd !== -1) {
        if (Object.keys(this.obbj[type]).length === 2) {
          delete this.obbj[type];
        } else {
          delete this.obbj[type][group];
        }
      } else {
        this.obbj = Object.assign({}, this.obbj, { [type]: Object.assign({}, this.obbj[type], { [group]: value }) })
      }
    } else {
      this.obbj = Object.assign({ [type]: { [group]: value, category: category } }, this.obbj);
    }
  }


  // submit the form
  doSubmit() {
    this.submitted = true;
    this.GeneralCheckList.forEach(element => {
      this.generalList[element.value] = element.status;
    });
    if (this.customForm.invalid) {
      this.toastr.error('Please fill all fields.');
      return;
    } else {
      if (this.customForm.controls.btn_url.value === '') {
        this.btnInfo = {
          buttonColor: this.customForm.controls.btn_color.value
        };
      } else {
        this.btnInfo = {
          buttonURL: this.customForm.controls.btn_url.value,
          buttonColor: this.customForm.controls.btn_color.value
        };
      }

      if (this.customForm.controls.top_feature1.value !== '') {
        this.topFeatured['feature1'] = this.customForm.controls.top_feature1.value;
      } else {
        delete this.topFeatured['feature1'];
      }

      if (this.customForm.controls.top_feature2.value !== '') {
        this.topFeatured['feature2'] = this.customForm.controls.top_feature2.value;
      } else {
        delete this.topFeatured['feature2'];
      }
      if (this.customForm.controls.top_feature3.value !== '') {
        this.topFeatured['feature3'] = this.customForm.controls.top_feature3.value;
      } else {
        delete this.topFeatured['feature3'];

      }
      if (this.customForm.controls.top_feature4.value !== '') {
        this.topFeatured['feature4'] = this.customForm.controls.top_feature4.value;
      } else {
        delete this.topFeatured['feature4'];
      }

      if (this.customForm.controls.top_feature5.value !== '') {
        this.topFeatured['feature5'] = this.customForm.controls.top_feature5.value;
      } else {
        delete this.topFeatured['feature5'];
      }
      this.topFeatured['isFeatured'] = this.customForm.controls.isFeatured.value;

      this.groupService.createPlan(this.customForm.value, this.generalList, this.obbj, this.topFeatured, this.btnInfo).then((response) => {
        // console.log(response);
        if (response) {
          this.toastr.success('New Package plan created.', ' Success!');
          this.userService.setValue(false);
        }
      });
    }
  }



  // hide the side bar
  hideThis() {
    this.userService.setValue(false);
  }

}
