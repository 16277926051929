export class AccountSettings {
    bgColor: string;
    exchange: string;
    pairs: string;
    isWatchlistActive: boolean;
    watchlist: [string];

    constructor() {
        this.bgColor = 'black';
        this.exchange = 'binance';
        this.pairs = 'ALL';
        this.isWatchlistActive = false;
        this.watchlist = null;
    }
}

export class PriceActionSettings {
    sortBy: string;
    move: string;
    view: string;
    type: string;
    reverseSort: boolean;

    constructor() {
        this.sortBy = 'n';
        this.move = 'buy_fibo';
        this.type = 'D7';
        this.view = 'trends_view';
        this.reverseSort = false;
    }
}

export class RsiScannerSettings {
    reverseSort: boolean;
    sortBy: string;
    type: string;

    constructor() {
        this.sortBy = 'n';
        this.reverseSort = false;
        this.type = 'rsi14';
    }
}

export class VolumeScannerSettings {
    reverseSort: boolean;
    sortBy: string;
    view: string;

    constructor() {
        this.sortBy = 'n';
        this.reverseSort = false;
        this.view = 'volume';
    }
}
