import { Component, Renderer2 } from '@angular/core';
import { CommonService } from './core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/auth.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Trading ABCD';
  public hideHeaderPages: Array<any> = ['/'];
  public layoutComponentShow = false;

  constructor(public commonservice: CommonService, public auth: AuthService, private renderer: Renderer2,
              private deviceService: DeviceDetectorService, private translate: TranslateService, private router: Router) {

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.commonservice.getCurrentUrl = event.url;
        if (this.hideHeaderPages.includes(event.url)) {
          this.layoutComponentShow = false;
          this.commonservice.isheadershow = false;
        } else {
          this.layoutComponentShow = true;
          this.commonservice.isheadershow = true;
        }
      }
    });

    this.translate.use('en');
    // this.auth.autoSelectLanguage();

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // console.log('prev:', event.url);
        if (event.url.indexOf('/ref/') > -1) {
          window.localStorage.setItem('referralID', event.url.substr(event.url.indexOf('ref') + 4));
        }
      }
    });
    
    this.commonservice.isTablet = this.deviceService.isTablet();



    this.commonservice.isMobile = this.deviceService.isMobile();
    if (this.deviceService.isMobile()) {
      this.renderer.addClass(document.body, 'is_mobile');
      this.commonservice.navPopUp = true;
    } else {
      this.renderer.addClass(document.body, 'is_desktop');
    }

    const this$ = this;
    auth.accountSettings$.subscribe({
      next(value) {

        if (!value) {
          this$.renderer.addClass(document.body, 'day-mode');
          this$.renderer.removeClass(document.body, 'night-mode');
          return;
        }

        if (value.bgColor === 'white') {
          this$.renderer.addClass(document.body, 'day-mode');
          this$.renderer.removeClass(document.body, 'night-mode');
        } else {
          this$.renderer.addClass(document.body, 'night-mode');
          this$.renderer.removeClass(document.body, 'day-mode');
        }
      }
    });
  }
}
