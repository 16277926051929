import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit, OnDestroy {

  titleMessage: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const body = document.body;
    body.classList.add('contact-modal');
  }

  ngOnDestroy() {
    const body = document.body;
    body.classList.remove('contact-modal');
  }

}
