import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../core/common.service';
import { Rsi } from '../models/rsi';
import { Globals } from '../core/globals';
import { ApiService } from '../core/api.service';
import { UserService, UserPreferenceType } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
@Component({
  selector: 'app-rsiscanner',
  templateUrl: './rsiscanner.component.html',
  styleUrls: ['./rsiscanner.component.scss']
})
export class RsiscannerComponent implements OnInit, OnDestroy {
  @Input() customStyle: {};
  @Output() setPageIdForParent = new EventEmitter<number>();
  rsiStats: Array<Rsi> = [];
  parsedData: Array<Rsi> = [];
  listToWatch: Array<string> = [];
  isWatchlistActive = false;
  rsiType = '';
  orderByField = 'pm';
  reverseSort = true;
  selectedPair = 'BTC';
  rsiRef: Subscription;
  isRefreshing: boolean = false;
  showWatchlistError: boolean = false;
  constructor(private apiService: ApiService, private router: Router, public commonservice: CommonService,
    public globals: Globals, private userService: UserService, private auth: AuthService, private modalService: NgbModal) {


    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    this.commonservice.footerShow = false;
    $(document).ready(() => {
      $(document).on("click", ".clone .fixstarrsi", (e) => {
        let $id = e.target.id;
        this.addToWatchlistrsi($id);
      })
    })
    const this$ = this;
    this.rsiRef = this.auth.rsiScannerSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.orderByField = settings.sortBy;
          this$.reverseSort = settings.reverseSort;
          this$.rsiType = settings.type;
        }
      }
    });
    this.auth.accountSettings$.subscribe({
      next(settings) {
        console.log("if settings");
        if (settings) {
          console.log("If are settings");
          this$.selectedPair = settings.pairs;
          console.log(settings, "Here we are logging the settings array :(");
          if (this$.isWatchlistActive !== settings.isWatchlistActive) {
            if (!this$.isWatchlistActive) {
              this$.rsiStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            } else {
              this$.rsiStats = this$.parsedData;
            }
          }

          this$.isWatchlistActive = settings.isWatchlistActive;

          if (settings.watchlist) {
            this$.listToWatch = settings.watchlist;
            if (this$.isWatchlistActive) {
              this$.rsiStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            }
          }
        }
      }
    });
    setTimeout(() => {
      this.getRsi();
    }, 2000)
  }
  refreshFilters() {
    this.isRefreshing = true;
    this.isWatchlistActive = false;
    this.watchlistPressedAll();
    this.rsiType = 'rsi7';
    this.orderByField = 'pm';
    this.reverseSort = true;
    this.selectedPair = 'BTC';
    setTimeout(() => { this.isRefreshing = false }, 300)
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }
  ngOnDestroy(): void {
    if (this.rsiRef) {
      this.rsiRef.unsubscribe();
    }
  }

  public getRsi() {
    this.apiService.getRsi().subscribe((data: string) => {
      const atobString = atob(data);
      const decodedString = this.globals.encryptXor(atobString);
      this.parsedData = JSON.parse(decodedString);

      if (this.isWatchlistActive) {
        this.rsiStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      } else {
        this.rsiStats = this.parsedData;
      }
      if(this.rsiStats.length == 0){
        this.showWatchlistError = true;
      }
      if (this.commonservice.isMobile) {
        $(document).ready(function () {
          $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
        });
      }
    });
  }

  public isWatched = (coin) => {
    return this.listToWatch.includes(coin);
  }

  addToWatchlistrsi(coin) {
    if (this.isWatched(coin)) {
      this.listToWatch = this.listToWatch.filter((ele) => {
        return ele !== coin;
      });

      if (this.isWatchlistActive) {
        this.rsiStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      }
    } else {
      this.listToWatch.push(coin);
    }

    this.userService.addToWatchlist(this.listToWatch);
    if (this.isWatchlistActive) {
      this.rsiStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    }
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  watchlistPressedAll() {
    this.rsiStats = this.parsedData;
    this.isWatchlistActive = false;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    this.showWatchlistError = false;
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }

  watchlistPressedStar() {
    this.rsiStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    this.isWatchlistActive = true;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if(this.rsiStats.length == 0){
      this.showWatchlistError = true;
    }else{
      this.showWatchlistError = false;
    }
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }
  watchlistPressed() {
    if (!this.isWatchlistActive) {
      this.rsiStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      this.isWatchlistActive = true;
    } else {
      this.rsiStats = this.parsedData;
      this.isWatchlistActive = false;
    }

    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }
  openPopUp($event, NgbdModalContent) {
    if ($event.target.tagName === 'IMG' || $event.target.tagName === 'img') {
      this.modalService.open(NgbdModalContent, { windowClass: 'showpopup' });
    }
  }
  mobileClick(item) {
    item.isExpanded = !item.isExpanded;
  }
  public checkOval = value => {
    if (value >= 23.6 && value <= 50) {
      return 'gradTealOval';
    } else if (value > 50 && value <= 78.6) {
      return 'gradPinkOval';
    } else {
      return 'gradnullOval';
    }
  }
  public rsiClass = value => {
    if (value <= 30) {
      return 'uptrendGreen';
    } else if (value >= 70) {
      return 'downtrend_grad_red';
    } else {
      return 'nullOval';
    }
  }
  // public rsiClass = value => {
  //   if (this.rsiType === 'rsi7') {
  //     if (value < 0.01) {
  //       return 'rsi_box';
  //     }
  //     if (value > 85) {
  //       return 'rsi_box border_blue';
  //     } else if (value < 15) {
  //       return 'rsi_box border_red';
  //     } else {
  //       return 'rsi_box';
  //     }
  //   } else if (this.rsiType === 'rsi14') {
  //     if (value < 0.01) {
  //       return 'rsi_box';
  //     }
  //     if (value > 70) {
  //       return 'rsi_box border_blue';
  //     } else if (value < 30) {
  //       return 'rsi_box border_red';
  //     } else {
  //       return 'rsi_box';
  //     }
  //   }
  // }

  public peakMoveClass = value => {
    if (value > 0) {
      return 'greenpm';
    } else if (value < 0) {
      return 'redpm';
    }
  }

  sortByField(field) {
    this.orderByField = field;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.RsiScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.RsiScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }

  secondLevelSort(column) {
    this.orderByField = this.rsiType + '.' + column;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.RsiScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.RsiScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }

  changePair(value) {
    this.selectedPair = value;
    this.userService.updateAccoutSettingsField(value, 'pairs');
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }
  changeType(value) {
    this.rsiType = value;
    this.userService.updateField(value, 'type', UserPreferenceType.RsiScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }
  changeComponent(value){
    this.setPageIdForParent.emit(value)
  }
  checkClass(index){
    return index==0?"longLengthLoader":index==1||index==11||index==12?"MediumLengthLoader":"smallLengthLoader";
  }
}
