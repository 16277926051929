import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = environment.apiURL;
  constructor(private httpClient: HttpClient) { }

  getPriceAction() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner', formData, { responseType: 'text' });
  }

  getRsi() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner-rsi', formData, { responseType: 'text' });
  }

  getFiboLevels() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner-fibo', formData, { responseType: 'text' });
  }

  getMovingAverageLevels() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner-ma', formData, { responseType: 'text' });
  }

  getMovingAverageCrossover() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner-ma-crossover', formData, { responseType: 'text' });
  }

  getVolume() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-scanner-volume', formData, { responseType: 'text' });
  }

  getMarketMonitors() {
    const formData = new FormData();
    formData.append('userId', '12345');
    return this.httpClient.post(this.apiUrl + '/generic/binance-market-monitor', formData, { responseType: 'text' });
  }
}
