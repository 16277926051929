import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BlogModel } from 'src/app/models/blogModel';
import { BlogService } from 'src/app/core/blog.service';
import { UploadService } from 'src/app/core/upload.service';
import { Upload } from 'src/app/models/upload';
import { AuthService } from 'src/app/core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/core/common.service';
import { database } from 'firebase/app';
declare var $: any;

@Component({
   selector: 'ms-blog-edit',
   templateUrl: './blog-edit-component.html',
   styleUrls: ['./blog-edit-component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class BlogEditComponent implements OnInit {

   public uploadValue: string;
   public editorForm: FormGroup;
   public blogModel: BlogModel;
   public selectedFiles: FileList;
   public currentUpload: Upload = null;
   private featuredimage: string;

   categoriesSource = [];
   packagesSource = [];
   categories = [];
   packages = [];
   dropdownCategoriesSettings = {};
   dropdownPackagesSettings = {};
   selectedCategories = [];
   blogId: string;
   blog;
   isEditing = false;
   isImageEdited = false;
   quillEditorRef;
   maxUploadFileSize = 1000000;
   contentData;
   public packagesRef;
   selectedPackages = [];
   user;

   constructor(public translate: TranslateService, private blogService: BlogService, private Activatedroute: ActivatedRoute,
               private uploadService: UploadService, public authService: AuthService, private commonservice: CommonService,
               private toastr: ToastrService, private router: Router) { }

   ngOnInit() {

      this.getUser();

      this.dropdownCategoriesSettings = {
         singleSelection: false,
         idField: 'id',
         textField: 'title',
         selectAllText: 'Select All',
         unSelectAllText: 'UnSelect All',
         itemsShowLimit: 3,
         allowSearchFilter: true
      };

      this.dropdownPackagesSettings = {
         singleSelection: false,
         idField: 'id',
         textField: 'name',
         selectAllText: 'Select All',
         unSelectAllText: 'UnSelect All',
         itemsShowLimit: 5,
         allowSearchFilter: true
      };

      this.commonservice.getCurrentUrl = this.router.url;
      if (this.commonservice.isMobile) {
         this.commonservice.navPopUp = true;
         window.scroll(0, 0);
      }

      this.editorForm = new FormGroup({
         title: new FormControl(null),
         categories: new FormControl(null),
         packages: new FormControl(null),
         editor: new FormControl(null)
      });

      this.authService.getBlogCategories().subscribe(categories => {
         this.categories = categories.map(p => ({ id: p.key, title: p.payload.val() }));
         // console.log(this.categories);

         this.authService.getPackages().subscribe(packages => {
          // const language = this.authService.getLanguage();
          const packagesIds = packages.map(p => p.key);
          const packagesList = packages.map(p => p.payload.val());
          // console.log(packages);

          // this.filterExact('language', language);
          this.packages = this.packages.filter(p => p.isFree === false);
          // console.log(this.packages);

          let i = 0;
          packagesList.forEach(packageObj => {
             const tempObj = { id: packagesIds[i], name: packageObj.package_info.name };
             // delete tempObj.isDisabled;
             this.packages.push(tempObj);
             i++;
          });

          this.Activatedroute.queryParamMap.subscribe(params => {
            this.blogId = params.get('id') || '';
            // console.log(this.blogId);
            if (this.blogId.length > 0) {
               this.getBlogData();
            }
         });
       });
      });
   }

   async getUser() {
      this.authService.user$.subscribe(user => this.user = user);
   }

   async getBlogData() {
      this.isEditing = true;

      this.blogService.getBlogById(this.blogId).subscribe(data => {
       this.blog = data;
       // console.log(this.blog);
       this.editorForm.get('title').setValue(this.blog.title);
       this.editorForm.get('editor').setValue(this.blog.content);
       this.contentData = this.blog.content;

       // tslint:disable-next-line: forin
       Object.keys(this.blog.categories).forEach((key, index) => {
        this.categories.forEach(cat => {
          if (key === cat.id) {
            this.selectedCategories.push(cat);
          }
        });
      });

       //  this.blog.categories.forEach(element => {
       //  });
       this.editorForm.get('categories').setValue(this.selectedCategories);
       this.blog.packages.forEach(element => {
         this.packages.forEach(pckg => {
           if (element === pckg.id) {
             this.selectedPackages.push(pckg);
           }
         });
       });
       this.editorForm.get('packages').setValue(this.selectedPackages);
     });
   }

   async onSubmit() {
      // console.log(this.editorForm.get('categories').value);
      // console.log(this.editorForm.get('description').value);

      const title = this.editorForm.get('title').value;
      let blogCategories = this.editorForm.get('categories').value;
      let packages = this.editorForm.get('packages').value;
      let content = this.editorForm.get('editor').value;
      // console.log(blogCategories);

      // console.log('isImageEdited ', this.isImageEdited);
      if (this.isImageEdited) {
         content = this.contentData;
      }

      if (packages && packages.length > 0) {
        packages = packages.map(c => c.id);
      } else {
        this.toastr.error('Packages missing',
         'Form not complete', { progressBar: true });
        return;
      }

      if (blogCategories && blogCategories.length > 0) {
        blogCategories = blogCategories.map(c => c.id);
      } else {
        this.toastr.error('Category missing',
         'Form not complete', { progressBar: true });
        return;
      }

      if (!this.isEditing && (this.featuredimage === undefined || this.featuredimage.length === 0)) {
        this.toastr.error('Featured image missing',
        'Form not complete', { progressBar: true });
        return;
      }

      if (!this.isEditing && (content === null || content.length === 0)) {
        this.toastr.error('Article content is missing',
        'Form not complete', { progressBar: true });
        return;
      }

      const categories = Object.assign.apply(null, blogCategories.map(x => ({[x]: true})));

      const blog = {
         title, language: 'en', categories, content,
         featureimage: this.featuredimage, packages
      };

      // console.log(blog);
      // console.log(this.blogId);

      if (this.isEditing) {
        if ((this.featuredimage === undefined || this.featuredimage.length === 0)) {
          const result = await this.blogService.updateBlog(blog, this.blogId, false);
        } else {
          const result = await this.blogService.updateBlog(blog, this.blogId, true);
        }
      } else {
         // this.blogService.addArticle(title, 'en', categories, content, this.featuredimage, packages);
         // this.firedataBlog.push(blog);
         const result = await this.blogService.createArticle(blog);
      }

      this.router.navigate(['/blog']);
   }

   goBack() {
    this.router.navigate(['/blog']);
   }

   resetForm() {
    this.router.navigate(['/blog']);
   }

   getFileToUpload(event) {
      this.selectedFiles = event.target.files;
      $('#featuredimage').val(this.selectedFiles.item(0).name);
      // console.log(this.selectedFiles.item(0).name);
   }

   async uploadFeaturedImage() {
      const file = this.selectedFiles.item(0);
      this.currentUpload = new Upload(file);
      const imgUrl = await this.uploadService.blogUpload(this.currentUpload);
      console.log(imgUrl);
      this.featuredimage = imgUrl;
      this.toastr.success(this.translate.instant('toastr.upload_complete'),
         this.translate.instant('toastr.congrats'), { progressBar: true });
   }

   async deleteArticle() {
      const result = await this.blogService.deleteDialog('Are you sure you want to delete this article?');
      // console.log(result);

      if (result) {
         await this.blogService.deleteBlog(this.blogId);
         this.router.navigate(['/blog']);
      }
   }

   getEditorInstance(editorInstance: any) {
      this.quillEditorRef = editorInstance;
      // console.log(this.quillEditorRef);
      const toolbar = editorInstance.getModule('toolbar');
      toolbar.addHandler('image', this.imageHandler);
      // console.log(this.quillEditorRef);
   }

   imageHandler = (image, callback) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';

      input.onchange = e => {
         // getting a hold of the file reference
         const file = input.files[0];

         // setting up the reader
         const reader = new FileReader();
         reader.readAsArrayBuffer(file);

         // here we tell the reader what to do when it's done reading...
         reader.onload = async readerEvent => {
            // const content = readerEvent.target.result; // this is the content!
            // console.log(content);
            // console.log(file);

            this.currentUpload = new Upload(file);
            const imageUrl = await this.uploadService.blogUpload(this.currentUpload);
            // console.log(imageUrl);

            const range = this.quillEditorRef.getSelection(true);
            const index = range.index + range.length;
            this.quillEditorRef.insertEmbed(range.index, 'image', imageUrl);
            this.isImageEdited = true;
            this.contentData = this.quillEditorRef.root.innerHTML;
         };
      };

      input.click();
   }
}
