import { Component, OnInit, Renderer2, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../core/common.service';
import { PriceAction } from '../models/price-action';
import { ApiService } from '../core/api.service';
import { Globals } from '../core/globals';
import { AuthService } from '../core/auth.service';
import { UserService, UserPreferenceType } from '../core/user.service';
import { Subscription, timer } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-priceaction',
  templateUrl: './priceaction.component.html',
  styleUrls: ['./priceaction.component.scss']
})
export class PriceactionComponent implements OnInit, OnDestroy {
  @Output() setPageIdForParent = new EventEmitter<number>();
  public navPopUp = false;
  public clickPopUp = false;
  public listcontent = false;
  public isClicked = false;
  public TrandsFiboVal = 'Trends';

  priceActionStats: Array<PriceAction> = [];
  parsedData: Array<PriceAction> = [];
  listToWatch: Array<string> = [];

  isWatchlistActive = false;
  type = 'D7';
  fibo = 'buy_fibo';
  backgroundMode = 'dark';
  slider = 'fibo';
  orderByField = 'peakStats.d7.pg';
  reverseSort = true;
  selectedPair = 'BTC';
  paRef: Subscription;
  isRefreshing: boolean = false;
  showWatchlistError: boolean = false;
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private modalService: NgbModal, public commonservice: CommonService, private apiService: ApiService, public globals: Globals, private auth: AuthService, private renderer: Renderer2, private userService: UserService) {
    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    $(document).ready(() => {
      $(document).on("click", ".clone .fixstarprice", (e) => {
        let $id = e.target.id;
        this.addToWatchlistact($id);
      })
    })
    const this$ = this;
    this.paRef = this.auth.priceActionSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.orderByField = settings.sortBy;
          this$.reverseSort = settings.reverseSort;
          this$.fibo = settings.move;
          this$.type = settings.type;
          this$.slider = settings.view;
        }
      }
    });

    this.auth.accountSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.selectedPair = settings.pairs;

          if (this$.isWatchlistActive !== settings.isWatchlistActive) {
            if (!this$.isWatchlistActive) {
              this$.priceActionStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            } else {
              this$.priceActionStats = this$.parsedData;
            }
          }

          this$.isWatchlistActive = settings.isWatchlistActive;

          if (settings.watchlist) {
            this$.listToWatch = settings.watchlist;
            if (this$.isWatchlistActive) {
              this$.priceActionStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            }
          }
        }
      }
    });
    setTimeout(() => {
      this.getPriceAction();
    }, 2000)
  }

  refreshFilters() {
    this.type = 'D7';
    this.fibo = 'buy_fibo';
    this.backgroundMode = 'dark';
    this.slider = 'fibo';
    this.orderByField = 'peakStats.d7.pg';
    this.isRefreshing = true;
    this.isWatchlistActive = false;
    this.watchlistPressedAll();
    this.reverseSort = true;
    this.selectedPair = 'BTC';
    setTimeout(() => { this.isRefreshing = false }, 300)
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }
  openPopUp($event, NgbdModalContent) {
    if ($event.target.tagName === 'IMG' || $event.target.tagName === 'img') {
      this.modalService.open(NgbdModalContent, { windowClass: 'showpopup' });
    }
  }
  ngOnDestroy(): void {
    if (this.paRef) {
      this.paRef.unsubscribe();
    }
  }

  sliderChange(value) {
    this.TrandsFiboVal = value;
    this.userService.updateField(value, 'view', UserPreferenceType.PriceAction);
  }

  public getPriceAction() {
    // Set up timer that ticks every X milliseconds
    // const timer$ = timer(0, 5000);
    this.apiService.getPriceAction().subscribe((data: string) => {
      const atobString = atob(data);
      const decodedString = this.globals.encryptXor(atobString);
      this.parsedData = JSON.parse(decodedString);
      console.log(JSON.parse(decodedString),"Every creation does not require a creator :(");
      
      if (this.isWatchlistActive) {
        this.priceActionStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      } else {
        this.priceActionStats = this.parsedData;
      }
      if(this.priceActionStats.length == 0){
        this.showWatchlistError = true;
      }
      if (this.commonservice.isMobile) {
        setTimeout(() => {
          $(".clone").remove();
          $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
        }, 100)
      }
    });
  }

  public isWatched = (coin) => {
    return this.listToWatch.includes(coin);
  }

  addToWatchlistact(coin) {
    console.log(coin, "--==");
    if (this.isWatched(coin)) {
      this.listToWatch = this.listToWatch.filter((ele) => {
        return ele !== coin;
      });

      if (this.isWatchlistActive) {
        this.priceActionStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      }
    } else {
      this.listToWatch.push(coin);
    }

    this.userService.addToWatchlist(this.listToWatch);
    if (this.isWatchlistActive) {
      this.priceActionStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    }
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  watchlistPressedAll() {
    this.priceActionStats = this.parsedData;
    this.isWatchlistActive = false;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    this.showWatchlistError = false;
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }

  watchlistPressedStar() {
    this.priceActionStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    this.isWatchlistActive = true;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if(this.priceActionStats.length == 0){
      this.showWatchlistError = true;
    }else{
      this.showWatchlistError = false;
    }
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }
  watchlistPressed() {
    if (!this.isWatchlistActive) {
      this.priceActionStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      this.isWatchlistActive = true;
    } else {
      this.priceActionStats = this.parsedData;
      this.isWatchlistActive = false;
    }

    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }

  navbaropen() {
    if (this.navPopUp) {
      this.navPopUp = false;
    } else {
      this.navPopUp = true;
    }
  }

  popupopen() {
    if (this.clickPopUp) {
      this.clickPopUp = false;
    } else {
      this.clickPopUp = true;
    }
  }
  openlist() {
    if (this.listcontent) {
      this.listcontent = false;
    } else {
      this.listcontent = true;
    }
  }

  mobileClick(item) {
    item.isExpanded = !item.isExpanded;
  }

  changePair(value) {
    this.selectedPair = value;
    this.userService.updateAccoutSettingsField(value, 'pairs');
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }

  changeFibo(value) {
    this.fibo = value;
    this.userService.updateField(value, 'move', UserPreferenceType.PriceAction);
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }

  changeType(value) {
    this.type = value;
    this.userService.updateField(value, 'type', UserPreferenceType.PriceAction);
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }

  sortByField(field) {
    this.orderByField = field;
    this.reverseSort = !this.reverseSort;
    this.userService.updateField(field, 'sortBy', UserPreferenceType.PriceAction);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.PriceAction);
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }

  secondLevelSort(column) {
    if (column === 'sortByVol') {
      this.orderByField = 'peakStats.' + this.type.toLowerCase() + '.v';
    } else if (column === 'sortByPg') {
      this.orderByField = 'peakStats.' + this.type.toLowerCase() + '.pg';
    } else if (column === 'sortByPl') {
      this.orderByField = 'peakStats.' + this.type.toLowerCase() + '.pl';
    } else if (column === 'sortByLowestLow') {
      this.orderByField = 'peakStats.' + this.type.toLowerCase() + '.llcmp';
    } else if (column === 'sortByHighestHigh') {
      this.orderByField = 'peakStats.' + this.type.toLowerCase() + '.hhcmp';
    }

    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.PriceAction);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.PriceAction);
    setTimeout(() => {
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
    }, 100)
  }
  public checkOval = value => {
    if (value >= 23.6 && value <= 50) {
      return 'gradTealOval';
    } else if (value > 50 && value <= 78.6) {
      return 'gradPinkOval';
    } else {
      return 'gradnullOval';
    }
  }
  public nameClass = (value) => {
    if (value == 2) {
      return 'triangle-copy-4.svg';
    } else if (value == 3) {
      return 'triangle-copy-3.svg';
    } else if (value == -2) {
      return 'triangle-copy-5.svg';
    } else if (value == -3) {
      return 'triangle-copy-2.svg';
    } else {
      return 'oval.svg';
    }
  }
  changeComponent(value) {
    this.setPageIdForParent.emit(value)
  }
  checkClass(index){
    return index==0?"longLengthLoader":index==1||index==10||index==11?"MediumLengthLoader":"smallLengthLoader";
  }
}
