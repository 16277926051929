import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/*========= All Components ===========*/
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChatComponent } from './chat/chat.component';
import { PriceactionComponent } from './priceaction/priceaction.component';
import { RsiscannerComponent } from './rsiscanner/rsiscanner.component';
import { VolumescannerComponent } from './volumescanner/volumescanner.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CanReadGuard } from './guards/can-read.guard';
import { CanReadPaidContentGuard } from './guards/can-read-paid-content.guard';
import { LoginGuard } from './guards/login.guard';
import { ReferralComponent } from './referral/referral.component';
import { LanguageComponent } from './language/language.component';
import { FibolevelsComponent } from './fibolevels/fibolevels.component';
import { MalevelsComponent } from './malevels/malevels.component';
import { BlogComponent } from './blog/blog.component';
// import { BlogpageComponent } from './blogpage/blogpage.component';
import { MacrossoverComponent } from './macrossover/macrossover.component';
import { PaymentHistoryComponent } from './admin/payment-history/payment-history.component';
import { SettingsComponent } from './settings/settings.component';
import { ExchangesComponent } from './exchanges/exchanges.component';
import { ScannersComponent } from './marketscanners/scanners.component';
import { WalletComponent } from './wallet/wallet.component';
import { PricingComponent } from './pricing/pricing.component';
// import { BlogListingComponent } from './blog/blog-listing/blog-listing.component';
import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { BlogEditComponent } from './blog/blog-edit/blog-edit.component';


const routes: Routes = [
  { path: '', component: HomeComponent , pathMatch: 'full', canActivate: [LoginGuard] },
  { path: 'login', component: LoginComponent  }, //, canActivate: [LoginGuard] },
  { path: 'home', component: HomeComponent , canActivate: [LoginGuard] },
  { path: 'pricing', component: PricingComponent},
  { path: 'language', component: LanguageComponent },
  { path: 'register', component: RegisterComponent   }, //, canActivate: [LoginGuard] },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'chat', component: ChatComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'priceaction', component: PriceactionComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'screenscanners', component: ScannersComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'rsiscanner', component: RsiscannerComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'fiboscanner', component: FibolevelsComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'mascanner', component: MalevelsComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'mascannercross', component: MacrossoverComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'volumescanner', component: VolumescannerComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'subscription', component: SubscriptionComponent }, // canActivate: [CanReadGuard] },
  { path: 'blog', component: BlogComponent, canActivate: [CanReadPaidContentGuard] },
  // { path: 'blog-list', component: BlogListingComponent, canActivate: [CanReadGuard] },
  { path: 'blog-detail', component: BlogDetailComponent, canActivate: [CanReadGuard] },
  { path: 'blog-edit', component: BlogEditComponent, canActivate: [CanReadPaidContentGuard] },
  
  // { path: 'blog-page/:slug', component: BlogpageComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'referral', component: ReferralComponent, canActivate: [CanReadGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [CanReadGuard] },
  { path: 'exchange', component: ExchangesComponent, canActivate: [CanReadGuard] },
  { path: 'package-manager', component: SubscriptionComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'payment-history', component: PaymentHistoryComponent, canActivate: [CanReadPaidContentGuard] },
  { path: 'invite/:id', redirectTo: '/register', pathMatch: 'prefix' },
  { path: 'wallet', component: WalletComponent },
  { path: '**', redirectTo: '/home' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
