import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Router } from '@angular/router';
import { CommonService } from '../core/common.service';
import { Globals } from '../core/globals';
import { UserService, UserPreferenceType } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { FiboLevels } from '../models/fiboLevels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fibolevels',
  templateUrl: './fibolevels.component.html',
  styleUrls: ['./fibolevels.component.scss']
})
export class FibolevelsComponent implements OnInit, OnDestroy {
  @Input() customStyle: {};
  fiboStats: Array<FiboLevels> = [];
  parsedData: Array<FiboLevels> = [];
  listToWatch: Array<string> = [];
  isWatchlistActive = false;
  fiboType = '';
  orderByField = 'n';
  reverseSort = true;
  selectedPair = 'BTC';
  fiboRef: Subscription;

  constructor(private apiService: ApiService, private router: Router, public commonservice: CommonService,
              public globals: Globals, private userService: UserService, private auth: AuthService) {
    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    const this$ = this;
    this.fiboRef = this.auth.rsiScannerSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.orderByField = settings.sortBy;
          this$.reverseSort = settings.reverseSort;
          this$.fiboType = settings.type;
        }
      }
    });

    this.auth.accountSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.selectedPair = settings.pairs;

          if (this$.isWatchlistActive !== settings.isWatchlistActive) {
            if (!this$.isWatchlistActive) {
              this$.fiboStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            } else {
              this$.fiboStats = this$.parsedData;
            }
          }

          this$.isWatchlistActive = settings.isWatchlistActive;

          if (settings.watchlist) {
            this$.listToWatch = settings.watchlist;
            if (this$.isWatchlistActive) {
              this$.fiboStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            }
          }
        }
      }
    });

    this.getFiboLevels();
  }

  public getFiboLevels() {
    this.apiService.getFiboLevels().subscribe((data: string) => {
      const atobString = atob(data);
      const decodedString = this.globals.encryptXor(atobString);
      this.parsedData = JSON.parse(decodedString);

      if (this.isWatchlistActive) {
        this.fiboStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      } else {
        this.fiboStats = this.parsedData;
      }
    });
  }

  public isWatched = (coin) => {
    return this.listToWatch.includes(coin);
  }

  addToWatchlist(coin) {
    if (this.isWatched(coin)) {
      this.listToWatch = this.listToWatch.filter((ele) => {
        return ele !== coin;
      });

      if (this.isWatchlistActive) {
        this.fiboStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      }
    } else {
      this.listToWatch.push(coin);
    }

    this.userService.addToWatchlist(this.listToWatch);
    if (this.isWatchlistActive) {
      this.fiboStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    }
  }

  watchlistPressed() {
    if (!this.isWatchlistActive) {
      this.fiboStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      this.isWatchlistActive = true;
    } else {
      this.fiboStats = this.parsedData;
      this.isWatchlistActive = false;
    }

    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
  }

  mobileClick(item) {
    item.isExpanded = !item.isExpanded;
  }

  ngOnDestroy(): void {
    if (this.fiboRef) {
      this.fiboRef.unsubscribe();
    }
  }

  public peakMoveClass = value => {
    if (value > 0) {
      return 'greenpm';
    } else if (value < 0) {
      return 'redpm';
    }
  }

  sortByField(field) {
    this.orderByField = field;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.FiboScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.FiboScanner);
  }

  secondLevelSort(column) {
    this.orderByField = this.fiboType + '.' + column;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.FiboScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.FiboScanner);
  }

  changePair(value) {
    this.selectedPair = value;
    this.userService.updateAccoutSettingsField(value, 'pairs');
  }

  changeType(value) {
    this.fiboType = value;
    this.userService.updateField(value, 'type', UserPreferenceType.FiboScanner);
  }

  public nameClass = (value) => {
    if (value === 2) {
      return 'green';
    } else if (value === 3) {
      return 'dgreen';
    } else if (value === -2) {
      return 'pink';
    } else if (value === -3) {
      return 'red';
    } else {
      return 'gray';
    }
  }


  public fiboClass = value => {
    if (value >= 0) {
      return 'txt_green';
    } else {
      return 'txt_pink';
    }
  }
}
