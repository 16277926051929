import { Component, OnInit,AfterViewChecked, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../core/common.service';
import { GroupService } from '../core/group.service';
import { UserService } from '../core/user.service';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../core/auth.service';
import { Upload } from '../models/upload';
import { UploadService } from '../core/upload.service';
import { FirebaseUserModel } from '../models/firebaseUser';
import * as _ from 'lodash';
import * as $ from 'jquery';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit,AfterViewChecked{
  @ViewChild('scroller')
  private feedContainer: ElementRef;
  public clickPopUp = false;
  public CategoryList: Array<any> = [];
  public openCatSelected: Array<any> = [];
  public selectedGroup: Array<any> = [];
  public groupMessages: Array<any> = [];
  public message = '';

  public replyTempActive = false;
  public tempReplyDetail: Array<any> = [];
  public onTop = false;
  public currentGroupData;
  public user: FirebaseUserModel = new FirebaseUserModel();
  public isChatLoader = false;
  public moveTobuttom = false;
  public isEmojes = false;
  public lastMessage: string;
  public selectedFiles: FileList;
  public currentUpload: Upload;

  public native = true;
  public set = 'native';
  public dummyImageUpload: any;
  public isDummyuploadimage = false;
  public isGroupSelected = false;
  public isChatOpen = false;
  public popupImagae: string;
  public selectedGroupInfo: Array<any> = [];
  public isAdmin = false;
  public packagesRef;
  public packages: any;
  public packageIds: any;
  public filters = {};
  public visibleGroups: Array<number> = [];
  public visible:boolean = true;
  public loader:boolean = true;
  constructor(@Inject(DOCUMENT) private document: Document,
        private router: Router, private modalService: NgbModal,
        public commonservice: CommonService, public groupservice: GroupService,
        public userService: UserService, private route: ActivatedRoute,
        private upSvc: UploadService, public authService: AuthService)  
      { }

  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }

  private applyFilters() {
    this.packages = _.filter(this.packages, _.conforms(this.filters));
  }

  emojiPickerOpen() {
    if (this.isEmojes) {
      this.isEmojes = false;
    } else {
      this.isEmojes = true;
    }
  }

  addEmoji($event) {
    // console.log($event.emoji.native);
    const textMessage = this.message;
    this.message = textMessage + $event.emoji.native;
  }
  ngAfterViewChecked(){
    $('textarea').on('keydown', function(e){
      if (e.keyCode != 13 && e.keyCode != 8 && e.keyCode != 46) {    
        return true;
      }
      console.log('keydown');
      
      var el = document.querySelector('textarea');
      var main_div = document.getElementById('chatmain');
      var reply_popup = document.getElementById('reply_popup');
      var ht = 26;
      // var mn_ht = 168;
      el.style.cssText = 'height:' + ht + 'px';
      if(reply_popup){  
        reply_popup.style.cssText = 'bottom:43px';  
      }
      if (e.keyCode == 13 && !e.shiftKey) {                
         var mn_ht = 159;
         main_div.style.cssText = 'height:'+'calc(100vh - '+mn_ht+'px)';
      }else{
        console.log(el.scrollHeight);
        if (e.keyCode == 13 && e.shiftKey) { 
          ht =(el.scrollHeight > 26)? el.scrollHeight+22:el.scrollHeight+14;
          el.style.cssText = 'height:' + ht + 'px';             
        }else{
          ht = (el.scrollHeight > 26)? el.scrollHeight+8:el.scrollHeight;
          el.style.cssText = 'height:' + ht + 'px';  
        }         
         if(el.scrollHeight < 74 && el.scrollHeight>30){
          var mn_ht = 132+el.scrollHeight;
          main_div.style.cssText = 'height:'+'calc(100vh - '+mn_ht+'px)';
          var rp = 16+el.scrollHeight;
          if(reply_popup){  
            reply_popup.style.cssText = 'bottom:'+rp+'px';
          }
         }else if(el.scrollHeight < 74){
          main_div.style.cssText = 'height:'+'calc(100vh - 159px)';
          var rp = 17+el.scrollHeight;
          if(reply_popup){  
            
            reply_popup.style.cssText = 'bottom:'+rp+'px';
          }
         } else{
          var mn_ht = 206;
          main_div.style.cssText = 'height:'+'calc(100vh - '+mn_ht+'px)';
          var rp = 89;
          if(reply_popup){  
            reply_popup.style.cssText = 'bottom:'+rp+'px';
          }
         }   
            
      }
      
      
    });
  }
  ngOnInit() {

    this.replyTempActive = false;
    this.commonservice.getCurrentUrl = this.router.url;
    const categoryData = this.groupservice.getCategory();

    if (this.commonservice.isMobile || this.commonservice.isTablet ) {
      this.visible = false;
      this.commonservice.navPopUp = true;
    }

    this.packagesRef = this.authService.getPackages().subscribe(pkgs => {
      const user = this.userService.currentUser;

      this.packages = pkgs.filter(p => p.key === user.packageId).map(p => p.payload.val());

      if (this.packages.length > 0) {
        this.visibleGroups = _.flatMap(this.packages[0].includedModules.group_category_ids);
      }
    });

    categoryData.snapshotChanges().subscribe(data => {
      this.CategoryList = [];
      let i = 1;
      data.forEach(item => {
        const cat = item.payload.toJSON();

        if (cat['language'] === undefined || cat['language'] !== this.authService.currentLanguage$.value) {
          return;
        }

        if (this.visibleGroups.indexOf(Number(cat['id'])) < 0) {
          return;
        }

        cat['key'] = item.key;

        let groups = [];
        let g = 1;

        for (var g_key in cat['groups']) {
          cat['groups'][g_key]['key'] = g_key;

          groups.push(cat['groups'][g_key]);
          if (i == 1 && g == 1 && this.selectedGroup.length == 0) {
            this.selectedGroup = cat['groups'][g_key];
            this.getGroupData(this.selectedGroup['key']);
          }
          g++;
        }

        cat['groups'] = groups;
        this.CategoryList.push(cat);
        if (i === 1 && this.openCatSelected.length === 0) {
          this.openCatSelected.push(cat['key']);
        }
        i++;
      });
    });

    const allUserIds = this.userService.getAllUsersId();
    allUserIds.snapshotChanges().subscribe(data => {
      const userData: Array<any> = [];
      data.forEach(item => {
        const usersIds = item.payload.toJSON();
        userData.push(usersIds);
      });
      this.userService.getAllUserIdsFb = userData;
    });

    this.tempReplyDetail = [{ username: '', color: '', message: '', is_image: '' }];
    console.log('data came from tempReplyDetail', this.tempReplyDetail);
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.user = data;
      }
    });

  }

  scrollToBottom(): void {
    this.moveTobuttom = false;
    this.feedContainer.nativeElement.scrollTop = this.feedContainer.nativeElement.scrollHeight;
  }

  openClose(catData) {
    const index = this.openCatSelected.indexOf(catData);
    if (index > -1) {
      this.openCatSelected.splice(index, 1);
    } else {
      this.openCatSelected.push(catData);
    }
  }

  isOpen(catData) {
    const index = this.openCatSelected.indexOf(catData);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  selectGroup(groupData) {
   

    this.selectedGroup = groupData;
    this.isGroupSelected = true;
    if (this.commonservice.isMobile || this.commonservice.isTablet) {
      // this.commonservice.isheadershow = false;
      this.visible = false;
    }
    this.isChatOpen = true;
    this.getGroupData(this.selectedGroup['key']);
    this.groupservice.setReadMessage(this.selectedGroup);
    if (this.currentGroupData) {
      this.currentGroupData.unsubscribe();
    }
  }

  getGroupData(groupId) {
    this.loader = true;
    this.selectedGroupInfo = [];
    const getGroupInfo = this.groupservice.getGroupInfo(groupId).snapshotChanges().subscribe(data => {
      data.forEach(item => {
        const groupInfo = item.payload.toJSON();
        groupInfo['key'] = item.key;
        if (item.key === 'groupinfo') {
          this.selectedGroupInfo.push(groupInfo);
        }
        this.loader = false;
        getGroupInfo.unsubscribe();
      });
      const userFbData = this.selectedGroupInfo[0].adminUsers;
      const userData = [];
      const currentuser = this.userService.currentUser;
      const cUid = currentuser['uid'];
      // tslint:disable-next-line:forin
      for (const u in userFbData) {
        userData.push(userFbData[u]);
      }
      const index = userData.indexOf(cUid);
      if (index > -1) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });

    this.groupMessages = [];
    const currentGroupData = this.groupservice.getGroupsMessages(groupId).snapshotChanges().subscribe(data => {
      data.forEach(item => {
        const groupMessg = item.payload.toJSON();
        groupMessg['key'] = item.key;
        this.groupMessages.push(groupMessg);
        currentGroupData.unsubscribe();
        setTimeout(() => { this.scrollToBottom(); }, 500);
      });

      if (this.groupMessages.length === 0) {
        return;
      }

      this.lastMessage = this.groupMessages[this.groupMessages.length - 1].key;
      this.currentGroupData = this.groupservice.getGroupsUnreadMessages(groupId).snapshotChanges().subscribe(data => {
        data.forEach(item => {
          const groupMessg = item.payload.toJSON();
          if (groupMessg['groupid'] === this.selectedGroup['key']) {
            groupMessg['key'] = item.key;
            if (this.lastMessage !== item.key) {
              this.groupMessages.push(groupMessg);
              this.lastMessage = item.key;
            }
          }
        });

        this.moveTobuttom = true;
      });
    });
  }

  reply(message) {
    this.replyTempActive = true;
    this.tempReplyDetail[0].username = message.username;
    this.tempReplyDetail[0].color = message.color;
    this.tempReplyDetail[0].message = message.message;
    this.tempReplyDetail[0].is_image = message.is_image;
    setTimeout(() => {
      var el = document.querySelector('textarea');
      var reply_popup = document.getElementById('reply_popup');
      if(el.scrollHeight<70){
        var rp = el.scrollHeight+10;  
      }else{
        var rp = 78;  
      }
      
      reply_popup.style.cssText = 'bottom:'+rp+'px';
    }, 50);
  }

  colseReply() {
    this.replyTempActive = false;
  }

  send() {
    let message = this.message.trim();
    this.message = '';
    this.isEmojes = false;
    const reply: boolean = this.replyTempActive;
    this.replyTempActive = false;
    this.groupservice.addgroupmsg(this.selectedGroup, message, reply, this.tempReplyDetail, false).then(() => {
      message = '';
      this.scrollToBottom();
    });
  }

  handleSubmit(event) {
    if (!this.commonservice.isMobile) {
      if (event.keyCode === 13 && !event.shiftKey) {
        this.isEmojes = false;
        if (this.message.trim() !== '') {
          this.send();
        }
        return false;
      }
    }
  }

  isMyMessage(uid) {
    const currentuser = this.userService.currentUser;
    if (currentuser['uid'] === uid) {
      return true;
    } else {
      return false;
    }
  }

  isSelected(groupData) {
    if (this.selectedGroup['key'] === groupData.key) {
      return true;
    } else {
      return false;
    }
  }

  public handleScroll(event: ScrollEvent) {
    if (event.isReachingBottom) {
      this.moveTobuttom = false;
    } else {
      this.moveTobuttom = true;
    }
    if (event.isReachingTop) {
      // console.log(`the user is reaching the Top`);
      if (!this.isChatLoader) {
        this.getPaginationMessage();
      }
    }
  }

  getPaginationMessage() {
    this.isChatLoader = true;
    const PaginationGroupMessages = [];
    const lastMessage = this.groupMessages[0].key;
    const getMessagesPage = this.groupservice.getPaginationGroupsMessages(this.selectedGroup['key'], lastMessage).snapshotChanges().subscribe(data => {
      data.forEach(item => {
        const groupMessg = item.payload.toJSON();
        groupMessg['key'] = item.key;
        if (this.groupMessages[0].key !== item.key) {
          PaginationGroupMessages.push(groupMessg);
        }
      });
      PaginationGroupMessages.reverse();
      getMessagesPage.unsubscribe();
      for (var gpm in PaginationGroupMessages) {
        this.groupMessages.unshift(PaginationGroupMessages[gpm]);
      }
      this.isChatLoader = false;
      setTimeout(() => {
        const lastMessageScrollPostion = this.document.getElementById('message_section' + lastMessage).offsetTop;
        this.feedContainer.nativeElement.scrollTop = lastMessageScrollPostion - 15;
      }, 1);
    });
  }

  checkUnread(groupData) {
    const currentuser = this.userService.currentUser;
    const userData = [];
    for (var u in groupData.unread) {
      userData.push(groupData.unread[u]);
    }

    const index = userData.indexOf(currentuser.uid);

    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  isNotVarified() {
    return this.user.isverified;
  }

  closeverfication() {
    this.commonservice.isverificationClose = true;
  }

  resendmail() {
    this.authService.SendVerificationMail();
  }

  checkCatUnread(categorydata) {
    let unreadStatus = 0;
    const currentuser = this.userService.currentUser;
    for (const g in categorydata.groups) {
      if (typeof categorydata.groups[g].unread !== "undefined") {
        const userData = [];
        for (var u in categorydata.groups[g].unread) {
          userData.push(categorydata.groups[g].unread[u]);
        }
        const cUid = currentuser['uid'];
        const index = userData.indexOf(cUid);
        if (index > -1) {
          unreadStatus++;
        }
      }
    }
    if (unreadStatus === 0) {
      return false;
    } else {
      return true;
    }
  }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    const currentuser = this.userService.currentUser;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.isDummyuploadimage = true;
      this.dummyImageUpload = reader.result;
      const groupMessg = [];
      const d = new Date().getTime();
      // console.log(d);
      const dummyId = 'dummy_image_' + d;
      groupMessg['key'] = dummyId;
      groupMessg['message'] = this.dummyImageUpload;
      groupMessg['is_image'] = true;
      groupMessg['is_dummyimage'] = true;
      groupMessg['sentby'] = currentuser['uid'];

      this.groupMessages.push(groupMessg);
      setTimeout(() => { this.scrollToBottom(); this.moveTobuttom = false; }, 500);
      this.currentUpload = new Upload(file);

      this.upSvc.pushUpload(this.currentUpload, this.selectedGroup, this.replyTempActive, this.tempReplyDetail, dummyId);
    };
  }
  searchback() {
    this.isChatOpen = false;
    this.isGroupSelected = false;
    this.commonservice.isheadershow = true;
    this.replyTempActive = false;
  }
  imagepopup($event, NgbdModalContent, image) {
    if ($event.target.tagName === 'DIV' || $event.target.tagName === 'div') {
      const modalRef = this.modalService.open(NgbdModalContent, { windowClass: 'image-popup' });
      this.popupImagae = image;
    }
  }

  linkify(text) {
    // tslint:disable-next-line:max-line-length
    const urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    return text.replace(urlRegex, url => {
      return '<a target="_blank" class="messages_link" href="' + url + '">' + url + '</a>';
    });
  }
}
