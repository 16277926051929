import { Component, OnInit, Renderer2 } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { AuthService } from "../../core/auth.service";
import { CommonService } from "../../core/common.service";
import * as _ from "lodash";
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: "app-pro",
  templateUrl: "./pro.component.html",
  styleUrls: ["./pro.component.scss"]
})
export class ProComponent implements OnInit {
  public  cnt:string = 'Select Country';
  public checkType: string;
  public billingForm: FormGroup;
  public addressForm: FormGroup;
  public open: boolean = false;
  public user$: any;
  public dropbox = false;
  public selectsubscribe = "halfmonth";
  public createPaymentsRef;
  public packRes:boolean = false;
  payments$;
  filteredPackages: any;
  packages: any = [] ;
  packageIds: Array<string> = [];
  selectedIndex;
  public packagesRef;
  /// Active filter rules
  filters = {};
  btnEnble: string;
  // public dollar: any;
  public yrAmt: any ;
  public submitted: boolean = false;

  constructor(public auth: AuthService, private userService: UserService, public commonService: CommonService, public render: Renderer2, private formBuilder: FormBuilder ) {}
 

  checkField(a) {
    this.checkType = a;
    if (this.checkType == "checked2") {
      this.commonService.subPayment = this.packages[1].package_info.price_1month;
      this.commonService.subPayTitle = "Pro monthly";
      this.btnEnble = "2";
      this.commonService.duration = 1;
    }
    if (this.checkType == "checked3") {
      this.commonService.subPayment = this.packages[1].package_info.price_1year;
      this.commonService.subPayTitle = "Pro yearly";
      this.btnEnble = "2";
      this.commonService.duration = 12;
    }

    // console.log("vaksjdf", this.commonService.subPayment);
    // console.log("Paytment Title", this.commonService.subPayTitle);
  }

  tryBilling() {
    // console.log("try Billing button working");
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      country: new FormControl("", [Validators.required]),
      companyName: new FormControl("", [Validators.required]),
      vat: new FormControl("", [Validators.required]),
      postal: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      street: new FormControl("", [Validators.required]),


    });



    



    this.auth.user$.subscribe(user => {
      this.user$ = user;
    });

    this.packagesRef = this.auth.getPackages().subscribe(packages => {
      this.packRes = true;
      
      const language = this.auth.getLanguage();
      this.packages = packages.map(p => p.payload.val());
      this.filterExact("language", language);

       //  this is for split Pro amount to two veriables
      let str2 = this.packages[1].package_info.price_1year;
      let str3 = str2 / 12;
      this.yrAmt = str3.toFixed(2).toString().split(".");


      for (const singlePckg of packages) {
        if (singlePckg.payload.val().language === language) {
          this.packageIds.push(singlePckg.key);
        }
      }
    });
  }
  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }
  private applyFilters() {
    this.packages = _.filter(this.packages, _.conforms(this.filters));
  }


  popUp() {
    this.auth.pymtOpn = true;
    if(!this.commonService.isMobile){
    this.render.addClass(document.body, "bg-disabled");
  }
}
  popUpClose() {
    this.render.removeClass(document.body, "bg-disabled");
    this.auth.pymtOpn = false;
    }

    selectCnt(val){
      this.cnt = val;
      this.open = false;
    }
    address(){
      this.submitted = true;
      console.log('Address form call', this.addressForm.value );
      let uid = this.userService.currentUser.uid;
      // console.log('user', uid);
      if (!this.addressForm.valid) {
        return;
      } else {
      
       this.userService.addressUpdate( uid, this.addressForm.value);
      }

      }
    get cCheck(){
      return this.addressForm.controls;
    }  
}
