import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = environment.apiURL;
  public coin = 'BTC';
  constructor(private httpClient: HttpClient) { }

  createPaymentRequest(userId, amount, userEmail, cryptoCurrency, duration, packageName): Observable<any> {
    // TODO: convert to json - https://stackoverflow.com/questions/37051476/how-do-i-post-json-in-angular-2
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('amount', amount);
    formData.append('userEmail', userEmail);
    formData.append('cryptoCurrency', cryptoCurrency);
    formData.append('fiatCurrency', 'USD');
    formData.append('duration', duration);
    formData.append('packageName', packageName);
    // console.log(formData);
    // console.log(this.apiUrl);

    return this.httpClient.post(this.apiUrl + '/payment/create', formData, { responseType: 'text' });
  }
}

