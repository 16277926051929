import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  private backgroundMode = 'dark';
  public tradingRoomConfigName = 'toolsTR';

  getTheme() {
    return this.backgroundMode;
  }

  isThemeDark() {
    return this.backgroundMode === 'dark';
  }

  setThemeColor(color) {
    this.backgroundMode = color;
  }

  changeTheme() {
    if (this.backgroundMode === 'dark') {
      this.backgroundMode = 'light';
    } else {
      this.backgroundMode = 'dark';
    }
  }

  encryptXor(text) {
    const key = 'kr1pt0';
    // tslint:disable-next-line:no-bitwise
    return Array.from(text, (c: string, i) => String.fromCharCode(c.charCodeAt(null) ^ key.charCodeAt(i % key.length))).join('');
  }
}
