import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fbTimeFormat'
})
export class FbTimeFormatPipe implements PipeTransform {

  Fulldays: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  transform(timestamp: any, args?: any): any {
        // console.log(args);
        var d = new Date(timestamp);
        var timeDiff = timestamp - Date.now();

        var hours = d.getHours();
        var minutes = ''+d.getMinutes();
        if(parseInt(minutes) < 10){
          minutes = '0' + d.getMinutes();
        }
        var date = d.getDate()+"";
        if(date.length < 2){
          var newDate = date;
          date =  "0" + newDate;
        }
        // var month = this.Months[d.getMonth()];
        var month = d.getMonth()+1;
        var year = d.getFullYear();
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        var diffYears = new Date().getFullYear() - d.getFullYear();

        var dd = "AM";
        var h = hours;
        if (h >= 12) {
          h = hours - 12;
          dd = "PM";
        }
        if (h == 0) {h = 12; }
        minutes = minutes.substr(-2);
        var formattedTime = '';

        if(diffYears === 0 && diffDays === 0){
          // "today"
           formattedTime = "Today , " +h + ":" + minutes +" "+ dd;
        }else if(diffYears === 0 && diffDays === -1) {
          //"Yesterday"
          // var formattedTime = 'Yesterday ,'+h + ":" + minutes +" "+ dd;
          if(args == 'category'){
            formattedTime = "Yesterday";
          }else if(args == 'chat'){
            formattedTime = "Yesterday , " +h + ":" + minutes +" "+ dd;
          }
        }else{
          if(args == 'category'){
            formattedTime = month < 10 ? date + '/0' + month + "/" + year : date + '/' + month + "/" + year;
          }else if(args == 'chat'){
            formattedTime = month < 10 ? date + '/0' + month + "/" + year+" "+h + ":" + minutes +" "+ dd : date + '/' + month + "/" + year+" "+h + ":" + minutes +" "+ dd;
          }
        }
        return formattedTime;


      /*var current: Date = new Date();
      var previous: Date = new Date(timestamp);

      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

    var elapsed: any = Math.abs(current.getTime() - previous.getTime());




    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' sec';
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' min';
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hr';
    }

    else if (elapsed < msPerMonth) {
         return  Math.round(elapsed/msPerDay) + ' days';
    }

    else if (elapsed < msPerYear) {
         return  Math.round(elapsed/msPerMonth) + ' months';
    }

    else {
         return Math.round(elapsed/msPerYear ) + ' years';
    }*/

  }
}
