import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { CommonService } from '../../core/common.service';
import { PaymentService } from '../../core/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentInfo } from '../../models/paymentInfo';
import { AngularFireDatabase } from '@angular/fire/database';
import * as _ from 'lodash';
@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.scss']
})
export class CryptoComponent implements OnInit {
  public open: boolean = false;
  paymentInfo = new PaymentInfo();
  public createPaymentsRef;
  packageIds: Array<string> = [];
  selectedIndex: any;
  stepOne: any;
  public packagesRef;
  filters = {};
  packages: any;
  constructor(
    public authService: AuthService,
    public commonService: CommonService,
    public paymentService: PaymentService,
    private modalService: NgbModal,
    public fdb: AngularFireDatabase,
     ) { }

  ngOnInit() {
    this.authService.paymtName = 'Crypto';
    this.openQrCode();
  }


  changeCoin(value) {
    this.paymentService.coin = value;
  }
  private applyFilters() {
    this.packages = _.filter(this.packages, _.conforms(this.filters));
  }
  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }
  async openQrCode() {
    this.createPaymentsRef = this.fdb.database.ref('/payments');
    this.packagesRef = this.authService.getPackages().subscribe(packages => {
      const language = this.authService.getLanguage();
      this.packages = packages.map(p => p.payload.val());
      this.filterExact('language', language);

      for (const singlePckg of packages) {
        if (singlePckg.payload.val().language === language) {
          this.packageIds.push(singlePckg.key);
        }
      }
     
    });
    const user = await this.authService.getUser();
    this.paymentService.createPaymentRequest(user.uid, this.commonService.subPayment, user.email, this.paymentService.coin,
      this.commonService.duration, this.commonService.subPayTitle)
      .subscribe(res => {
        const json = JSON.parse(res);
        // console.log(json);
        this.paymentInfo.qrCode = json.qrCode;
        this.paymentInfo.statusUrl = json.statusUrl;
        this.paymentInfo.amount = json.amount;
        this.paymentInfo.cryptoAddress = json.cryptoAddress;
        this.paymentInfo.currency = json.currency;
        this.paymentInfo.packageName = this.commonService.subPayTitle;
        this.paymentInfo.transactionId = json.transactionId;

        // this.modalService.open(content, { windowClass: 'steptwo purchase-detail-popup' });
        // console.log(this.paymentInfo.qrCode, '7008001'); return
        this.createPaymentsRef.child(user.uid).child(this.paymentInfo.transactionId).set({
          statusUrl: this.paymentInfo.statusUrl,
          packageName: this.commonService.subPayTitle,
          packageId: this.packageIds[1],
          duration:  this.commonService.duration,
          cratedOn: this.authService.timestamp
        });

      }, error => {
          console.log(error);
          
      });
  }
  

}
