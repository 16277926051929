import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import { UserService } from "../core/user.service";
import {  FormGroup, FormBuilder, FormControl, Validators} from "@angular/forms";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../core/common.service";
import { RestService } from "../core/rest.service";
import { AuthService } from "../core/auth.service";
import { TransactionHistory } from "../models/transactionHistory";
import { TranslateService } from "@ngx-translate/core";
import { SocialService } from "ngx-social-button";

@Component({
  selector: "app-referral",
  templateUrl: "./referral.component.html",
  styleUrls: ["./referral.component.scss"]
})



export class ReferralComponent implements OnInit {
  @Input() text = '';



  public fileToUpload: File = null;
  public open: boolean = false;
  public hide: boolean = false;
  public hide1: boolean = false;
  public hide2: boolean = false;
  public hide3: boolean = false;
  public hide4: boolean = false;
  public popupopen: boolean = false;
  public dollar: number;
  public cent: any = 0;

  private modalRef: any;
  public bigData: any;
  public userData: any;
  withdrawalForm: FormGroup;
  public refLink = environment.refLink;
  transactionHistory: TransactionHistory;

  dataLoaded = false;
  recordLoaded = false;
  transactions: any;
  totalPages: any;
  totalRecords: any;
  recordPerPage: any;
  page = 1;
  isRecordEmpty = true;
  public tranStatus: string = "";

  shareObj = { href: this.refLink + "this.bigData.referal_key", 
  hashtag: this.refLink + "this.bigData.referal_key", 
  discription: this.refLink + "this.bigData.referal_key"
};
  centZero: boolean;

  constructor(
    public userService: UserService,
    public rest: RestService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public commonservice: CommonService,
    private authService: AuthService,
    private translate: TranslateService,
    private socialAuthService: SocialService,
    public render: Renderer2
  ) {

    const url = 'https://platform.twitter.com/widgets.js';
    if (!document.querySelector(`script[src='${url}']`)) {
        let script = document.createElement('script');
        script.src = url;
        document.body.appendChild(script);
    }

  }

  ngOnInit() {
    this.commonservice.footerShow = false;
    this.withdrawalForm = this.formBuilder.group({
      walletID: new FormControl("", Validators.compose([Validators.required]))
    });

    this.authService.getUser().then(user => {
      const userData = this.userService.currentUserCollection(user.uid);
      userData.valueChanges().subscribe(data => {
        this.userData = data;
        this.getDashboardData();
        this.getTransactionHistory(this.page);
      });
    });
  }

  getDashboardData() {
    this.rest.getUserReferralData(this.userData.uid).then(response => {
      console.log(response);
      this.bigData = response;
      this.dataLoaded = true;

      let str = this.bigData.balance.toString();
      let str1 = str.split(".");
      this.dollar = parseInt(str1[0]);
      this.cent =  str1[1];
      if(str1[1] ==0){
        this.centZero = false;
      }
    });
  }

  getTransactionHistory(offset) {
    this.rest
      .getTransactionHistory(this.userData.uid, offset)
      .then((res: any) => {
        this.transactionHistory = res;
        this.transactions = this.transactionHistory.withdrawals;
        this.totalPages = this.transactionHistory.totalPages;
        this.recordLoaded = true;
        this.totalRecords = this.transactionHistory.totalRecords;
        this.recordPerPage = this.transactionHistory.recordsPerPage;
        this.isRecordEmpty = false;


        if(this.transactions.status == 0){ this.tranStatus = "Processing"}
        if(this.transactions.status == 1){ this.tranStatus = "Completed"}
        if(this.transactions.status == 2){ this.tranStatus = "Rejected"}
        




      });
  }



  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    this.commonservice.success();
    this.commonservice.msg = "Copy Successfully";
  }

  initiateWithdrwal() {
   
    if (this.withdrawalForm.controls["amount"].value < 25) {
      this.commonservice.error();
      this.commonservice.msg = "Minimum Withdrawal Amount must be $25.";
    } else {
      this.rest
        .moneyWithdrawalRequest(this.userData.uid, this.withdrawalForm.value)
        .then(res => {
         if (res["status"]) {
            this.modalRef.close();
            this.getDashboardData();
            this.getTransactionHistory(this.page);
          } else if (!res["status"] && res["error"]) {
            this.commonservice.error();
            this.commonservice.msg = res["error"];
          } else {
            this.commonservice.error();
            this.commonservice.msg = "Something went wrong. Try again later";
          }
        });
    }
    this.popUpClose();
  }

  fetchRecord(pageid) {
    this.page = pageid;
    this.getTransactionHistory(pageid);
  }

  public facebookSharing() {
    this.socialAuthService.facebookSharing(this.shareObj);
  }
  popUp() {
    this.popupopen = true;
    this.render.addClass(document.body, "bg-disabled");
  }
  popUpClose() {
    this.render.removeClass(document.body, "bg-disabled");
    this.popupopen = false;
    }
}
