import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { database, auth } from 'firebase/app';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userPreferenceConst = 'user_preferences';
  accountSettingsConst = 'account_settings';
  watchlist$: AngularFireList<any>;
  userId: string;
  currentUser;
  subscription_list = 'subscriptions';
  first_list = 'first_list';
  public night:boolean = true;

  public currentUseData: AngularFireObject<any>;
  public getAllUserIds: AngularFireList<any>;
  public firedataUsersIds;
  public getAllUserIdsFb: Array<any> = [];
  

  showThis = new BehaviorSubject(false);

  constructor(public fdb: AngularFireDatabase, private authService: AuthService, private tostr:CommonService ) {
    authService.user$.subscribe(user => {
      if (user) {
        this.watchlist$ = this.fdb.list(`/${this.userPreferenceConst}/${user.uid}/${this.accountSettingsConst}/watchlist`);
        this.userId = user.uid;
        this.currentUser = user;
      }
    });
  }

  // this is for email Subscriber
  trySubscrib(formValue) {
    return new Promise((resolve, reject) => {
      let str = formValue.email;
      str = str.toLowerCase();
      const d = new Date().getTime();
      const arr = { 'time': d, 'email': str };
      database().ref(`/${this.subscription_list}`).orderByChild("email").equalTo(str).once("value", snapshot => {
        if (snapshot.exists()) {
            resolve(false)
        } else {
          database().ref(`/${this.subscription_list}`).push(arr);
          resolve(true)
        }
      });
    })
  }


  newsPreferences(news) {
    return new Promise((resolve, reject) => {
      database().ref(`/${this.userPreferenceConst}/${this.userId}/${this.accountSettingsConst}/`).child('newsUpdates').set(news);
      })
   
  }

  loginPreferences(loginNote) {
    return new Promise((resolve, reject) => {
      database().ref(`/${this.userPreferenceConst}/${this.userId}/${this.accountSettingsConst}/`).child('loginNotification').set(loginNote);
    })
   
  }


  addToWatchlist(array: Array<string>): void {
    database().ref(`/${this.userPreferenceConst}/${this.userId}/${this.accountSettingsConst}/`).child('watchlist').set(array);
  }

  updateAccoutSettingsField(value: any, field: string): void {
    database().ref(`/${this.userPreferenceConst}/${this.userId}/${this.accountSettingsConst}/`).child(field).set(value);
  }

  updateField(value: any, property: string, type: UserPreferenceType): void {
    database().ref(`/${this.userPreferenceConst}/${this.userId}/${type}/`).child(property).set(value);
  }

  getAllUsersId() {
    this.getAllUserIds = this.fdb.list('usernames');
    return this.getAllUserIds;
  }

  currentUserCollection(id) {
    this.currentUseData = this.fdb.object('users/' + id);
    return this.currentUseData;
  }

  // for subscrib address save in database
  addressUpdate(id, add){
    this.fdb.database.ref("/users").child(id).update({ address: add })
    .then(() => {
     this.tostr.success();
     this.tostr.msg ="Address Update Successfully";
    })
    .catch(err => {
      this.tostr.error();
     this.tostr.msg ="Something Went Wrong! please update after some time";

      throw (err);
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      const user = auth().currentUser;
      user.updateProfile({
        displayName: value.name,
        photoURL: user.photoURL
      }).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  setValue(val) {
    this.showThis.next(val);
  }
}

export enum UserPreferenceType {
  PriceAction = 'price_action_settings',
  RsiScanner = 'rsi_scanner_settings',
  VolumeScanner = 'volume_scanner_settings',
  FiboScanner = 'fibo_scanner_settings',
  MaScanner = 'ma_scanner_settings'
}
