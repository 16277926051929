import { Injectable, Inject } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { DOCUMENT } from '@angular/platform-browser';
import { Upload } from '../models/upload';
import { GroupService } from './group.service';
import { UserService } from './user.service';
import { storage } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath = '/uploads';
  uploads: AngularFireList<Upload[]>;

  constructor(@Inject(DOCUMENT) private document: Document,
              public fdb: AngularFireDatabase, public groupservice: GroupService, public userService: UserService) { }

  pushUpload(upload: Upload, selectedGroup, replyTempActive, tempReplyDetail, dummyId) {
 const d = new Date().getTime();
 var str = upload.file.name;
 var file_name = str.replace(/[^a-zA-Z ]/g, "");
 const imagename = d + file_name;
//  const imagename  = d + upload.file.name;
 const storageRef = storage().ref();
 storageRef.child(`${this.basePath}/${imagename}`).put(upload.file).then((res) => {
      storageRef.child(`${this.basePath}/${imagename}`).getDownloadURL().then((url) => {
        // console.log(url);
        const message = url;
        this.groupservice.addgroupmsg(selectedGroup, message, replyTempActive, tempReplyDetail, true).then(() => {
          // console.log(dummyId);
          this.document.getElementById('message_section' + dummyId).remove();
        });
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  async blogUpload(upload: Upload): Promise<string> {
    const d = new Date().getTime();
    const imagename = d + upload.file.name;
    const storageRef = storage().ref();
    await storageRef.child(`blog/${imagename}`).put(upload.file);
    const url = await storageRef.child(`blog/${imagename}`).getDownloadURL();
    console.log('blog image uploaded to url ', url);
    return url;
  }
}
