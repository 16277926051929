import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../core/common.service";
import { PaymentService } from "../core/payment.service";
import { AuthService } from "../core/auth.service";
import { PaymentInfo } from "../models/paymentInfo";
import { Subscription } from "rxjs";
import { UserService } from "../core/user.service";
import { AngularFireDatabase } from "@angular/fire/database";
import * as _ from "lodash";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"]
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  msg: any;
  changePassError: boolean = false;
  changePassSuccess: boolean = false;
  fileToUpload: File = null;
  public open: boolean = false;
  public stater: boolean = false;

  public dropbox = false;
  public selectsubscribe = "halfmonth";
  displayPayment = "none";
  displayQrCode = "none";
  priceToPay;
  totalPrice;
  subscriptionName = "";
  subscriptionPeriod = "1 year";
  packageId = "";
  duration;
  payBtnDisabled = false;
  paymentInfo = new PaymentInfo();
  stepOne: NgbModalRef;
  userRef: Subscription;
  showThis = false;
  coin = "BTC";
  public createPaymentsRef;
  user$;
  payments$;
  filteredPackages: any;
  packages: any;
  packageIds: Array<string> = [];
  selectedIndex;
  public packagesRef;
  /// Active filter rules
  filters = {};
  paytitle: string = "Credit Card";
  public open1: boolean = false;

  constructor(
    private router: Router,
    public commonservice: CommonService,
    public auth: AuthService,
    private modalService: NgbModal,
    public paymentService: PaymentService,
    public userService: UserService,
    public fdb: AngularFireDatabase,
    public render: Renderer2
  ) {}

  private applyFilters() {
    this.packages = _.filter(this.packages, _.conforms(this.filters));
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }

  ngOnInit() {
    this.commonservice.footerShow = false;
    this.createPaymentsRef = this.fdb.database.ref("/payments");
    this.auth.user$.subscribe(user => {
      this.user$ = user;
    });

    this.packagesRef = this.auth.getPackages().subscribe(packages => {
      // console.log("for packages", packages);
      const language = this.auth.getLanguage();
      this.packages = packages.map(p => p.payload.val());
      this.filterExact("language", language);

      for (const singlePckg of packages) {
        if (singlePckg.payload.val().language === language) {
          this.packageIds.push(singlePckg.key);
        }
      }
    });

    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }

    this.auth.getUser().then(user => {
      this.auth.getPayments(user.uid).subscribe(payments => {
        this.payments$ = payments.map(p => p.payload.val());
      });
    });

    this.userService.showThis.subscribe(state => {
      this.showThis = state;
    });
  }

  purchasepopup(content, index) {
    const currentProduct = this.packages[index];
    this.selectedIndex = index;
    this.stepOne = this.modalService.open(content, {
      windowClass: "stepone purchase-popup"
    });

    if (this.selectsubscribe === "month") {
      this.priceToPay = currentProduct.package_info.price_1month;
      this.subscriptionPeriod = "1 month";
      this.duration = 1;
    } else if (this.selectsubscribe === "halfmonth") {
      this.priceToPay = currentProduct.package_info.price_6month;
      this.subscriptionPeriod = "6 months";
      this.duration = 6;
    } else if (this.selectsubscribe === "year") {
      this.priceToPay = currentProduct.package_info.price_1year;
      this.subscriptionPeriod = "1 year";
      this.duration = 12;
    }

    this.totalPrice = this.priceToPay;
    this.subscriptionName = currentProduct.package_info.name;
  }

  changeCoin(value) {
    this.coin = value;
  }

  btcpopup() {
    if (this.dropbox) {
      this.dropbox = false;
    } else {
      this.dropbox = true;
    }
  }

  selectsubscribefn(type) {
    this.selectsubscribe = type;
  }

  startLoading() {
    // console.log('here');
    // this.progressRef.start();
  }

  async openQrCode(content) {
    this.payBtnDisabled = true;
    const user = await this.auth.getUser();
    this.paymentService
      .createPaymentRequest(
        user.uid,
        this.totalPrice,
        user.email,
        this.coin,
        this.duration,
        this.subscriptionName
      )
      .subscribe(
        res => {
          const json = JSON.parse(res);
          // console.log(json);
          this.paymentInfo.qrCode = json.qrCode;
          this.paymentInfo.statusUrl = json.statusUrl;
          this.paymentInfo.amount = json.amount;
          this.paymentInfo.cryptoAddress = json.cryptoAddress;
          this.paymentInfo.currency = json.currency;
          this.paymentInfo.packageName = this.subscriptionName;
          this.paymentInfo.transactionId = json.transactionId;

          this.modalService.open(content, {
            windowClass: "steptwo purchase-detail-popup"
          });

          this.createPaymentsRef
            .child(user.uid)
            .child(this.paymentInfo.transactionId)
            .set({
              statusUrl: this.paymentInfo.statusUrl,
              packageName: this.subscriptionName,
              packageId: this.packageIds[this.selectedIndex],
              duration: this.duration,
              cratedOn: this.auth.timestamp
            });

          this.stepOne.close();
          this.payBtnDisabled = false;
        },
        error => {
          console.log(error);
          this.payBtnDisabled = false;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.userRef) {
      this.userRef.unsubscribe();
    }

    if (this.packagesRef) {
      this.packagesRef.unsubscribe();
    }

    if (this.createPaymentsRef) {
      this.createPaymentsRef = null;
    }
  }

  openDialog() {
    this.userService.setValue(true);
  }

  getPay(a) {
    if (a == "cc") {
      this.auth.paymt = a;
      this.paytitle = "Credit Card";
      this.open1 =  false;
    }
    if (a == "paypal") {
      this.auth.paymt = a;
      this.paytitle = "Paypal";
      this.open1 =  false;
    }
    if (a == "crypto") {
      this.auth.paymt = a;
      this.paytitle = "Crypto";
      this.open1 =  false;
    }
  }
  popUp() {
    // this.auth.pymtOpn = true;
    if (!this.commonservice.isMobile) {
      this.render.addClass(document.body, "bg-disabled");
    }
  }
  popUpClose() {
    this.render.removeClass(document.body, "bg-disabled");
    this.auth.pymtOpn = false;
  }
}
