import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { take, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanReadPaidContentGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(public auth: AuthService, private router: Router) { }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.user$.pipe(
        take(1),
        tap(user => {
          if (!user) {
            this.router.navigate(['/login']);
          }
        }),
        map(user => user && this.auth.canReadPaid(user) ? true : false),
        tap(canView => {
          if (!canView) {
            console.error('Access denied. Must have permissions to view content');
            this.router.navigate(['/subscription']);
          }
        })
      );
    }
}
