export class PaymentInfo {
    qrCode: string;
    statusUrl: string;
    cryptoAddress: string;
    amount: number;
    currency: string;
    packageName: string;
    transactionId: string;
}

export class Payment {
    name: string;
    cashAmount: number;
    cratedOn: number;
    startDate: string;
    endDate: string;
    status: string;
    link: string;
}
