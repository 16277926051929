import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { database } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})

export class GroupService {

  public firedataUsername;
  public firedataUserData;
  public firedataGroupCategory;
  public firedataGroups;
  public catGroupData: AngularFireList<any>;
  public currentuser;
  public getGroupData: AngularFireList<any>;


  constructor(public afAuth: AngularFireAuth,
              public fdb: AngularFireDatabase,
              public userService: UserService,
              public auth: AuthService) {
    this.firedataUsername = this.fdb.database.ref('/usernames');
    this.firedataUserData = this.fdb.database.ref('/allusers');
    this.firedataGroupCategory = this.fdb.database.ref('/group_category');
    this.firedataGroups = this.fdb.database.ref('/groups');
  }

  getCategory() {
    this.catGroupData = this.fdb.list('group_category', ref => ref.orderByChild('order'));
    return this.catGroupData;
  }

  getGroupInfo(groupname) {
    const getGroupData = this.fdb.list('/groups/' + groupname);
    return getGroupData;
  }

  getGroupsMessages(groupname) {
    const getGroupData = this.fdb.list('/groups/' + groupname + '/msgboard/', ref => ref.limitToLast(10).orderByKey());
    return getGroupData;
  }

  getGroupsUnreadMessages(groupname) {
    this.getGroupData = this.fdb.list('/groups/' + groupname + '/msgboard/', ref => ref.limitToLast(1).orderByKey());
    return this.getGroupData;
  }

  getPaginationGroupsMessages(groupname, lastMessage) {
    const getGroupData = this.fdb.list('/groups/' + groupname + '/msgboard/', ref => ref.endAt(lastMessage).limitToLast(10).orderByKey());
    return getGroupData;
  }

  addgroupmsg(groupData, newmessage, isReply, tempReplyDetail, is_image) {

    this.currentuser = this.userService.currentUser;
    return new Promise((resolve) => {
      if (isReply === false) {
        this.firedataGroups.child(groupData.key).child('msgboard').push({
          sentby: this.currentuser.uid,
          groupid: groupData.key,
          // displayName: this.currentuser.displayName,
          username: this.currentuser.userName,
          initial: this.currentuser.initial,
          color: this.currentuser.color,
          message: newmessage,
          timestamp: database.ServerValue.TIMESTAMP,
          isReply: isReply,
          is_image: is_image
        }).then(() => {
          // alert('provider than');
          this.updateLastMsgDetailInGategory(groupData);
          resolve(true);
        });
      } else {
        // alert('provider reply');
        /*this.firegroup.child(this.currentgroupname).child('msgboard').push({
          sentby: this.currentuser['id'],
          displayName: this.currentuser['fullname'],
          username: this.currentuser['username'],
          photoURL: this.currentuser['photourl'],
          country: this.currentuser['country'],
          message: newmessage,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          isReply: isReply,
          is_image: is_image,
          repliedMsgUsrName: tempReplyDetail[0].username,
          repliedMsgUsrMsg: tempReplyDetail[0].usermsg,
          repliedMsgUsrTimestamp: tempReplyDetail[0].msgtime,
          isReplyOfMsgImg: tempReplyDetail[0].isReplyOfMsgImg
        }).then(() => {
          // alert('provider reply than');
            this.updateLastMsgDetail(newmessage,is_image);
            resolve(true);
        })*/
        this.firedataGroups.child(groupData.key).child('msgboard').push({
            sentby: this.currentuser.uid,
            groupid: groupData.key,
            // displayName: this.currentuser.displayName,
            username: this.currentuser.userName,
            initial: this.currentuser.initial,
            color: this.currentuser.color,
            message: newmessage,
            timestamp: database.ServerValue.TIMESTAMP,
            isReply: isReply,
            is_image: is_image,
            repliedMsgUsrName: tempReplyDetail[0].username,
            repliedMsgUsrMsg: tempReplyDetail[0].message,
            isReplyOfMsgImg: tempReplyDetail[0].is_image,
            repliedMsgUsrColor: tempReplyDetail[0].color
        }).then(() => {
          // alert('provider than');
            this.updateLastMsgDetailInGategory(groupData);
            resolve(true);
        })

      }
    });
  }

  updateLastMsgDetailInGategory(groupData) {
    const categoryPath = 'category_' + groupData.category;
    const groupPath = groupData.key;
    this.firedataGroupCategory.child(categoryPath).child('groups').child(groupPath).update({
      lasttimestamp: database.ServerValue.TIMESTAMP
    }).then(() => {

    });
    this.currentuser = this.userService.currentUser;
    const userData = this.userService.getAllUserIdsFb;
    const cUid = this.currentuser.uid;
    const index = userData.indexOf(cUid);
    if (index > -1) {
      userData.splice(index, 1);
    }

    this.firedataGroupCategory.child(categoryPath).child('groups').child(groupPath).child('unread').set(userData);
  }

  setReadMessage(groupData) {
    const categoryPath = 'category_' + groupData.category;
    const groupPath = groupData.key;

    this.currentuser = this.userService.currentUser;
    const userFbData = groupData.unread;

    const userData = [];
    for (var u in userFbData) {
      userData.push(userFbData[u]);
    }

    const cUid = this.currentuser.uid;
    const index = userData.indexOf(cUid);
    if (index > -1) {
      userData.splice(index, 1);
    }
    this.firedataGroupCategory.child(categoryPath).child('groups').child(groupPath).child('unread').set(userData);
  }

  // firebase function
  getGroupCategories() {
    this.catGroupData = this.fdb.list('firedataGroups');
    return this.catGroupData;
  }

  createPlan(data, generalList, groupList, topFeatures, button) {
    return new Promise((resolve, reject) => {
      this.fdb.list('packageManager').push({
        package_info: {
          name: data.package_name,
          price_1month: data.one_month_rate,
          price_6month: data.six_month_rate,
          price_1year: data.one_year_rate,
        },

        referral: {
          fTimeLevel1: {
            '1month': data.ft_l1_month,
            '6month': data.ft_l1_halfY,
            '1year': data.ft_l1_fullY,
          },

          fTimeLevel2: {
            '1month': data.ft_l2_month,
            '6month': data.ft_l2_halfY,
            '1year': data.ft_l2_fullY,
          },

          recurringLevel1: {
            '1month': data.rc_l1_month,
            '6month': data.rc_l1_halfY,
            '1year': data.rc_l1_fullY,
          },

          recurringLevel2: {
            '1month': data.rc_l2_month,
            '6month': data.rc_l2_halfY,
            '1year': data.rc_l2_fullY,
          }
        },

        topFeatures,
        includedModules: {
          groupList,
          generalSection: {
            priceAction: generalList.priceAction,
            RSIscanner: generalList.RSIscanner,
            volumeScanner: generalList.volumeScanner,
            algoTradeJournal: generalList.algoTradeJournal
          }
        },
        button
      }).then(res => {
        resolve(true);
      }, (err) => {
        resolve(false);
        reject(err);
      });
    });
  }
}
