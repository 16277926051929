import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { CommonService } from '../../core/common.service';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/core/user.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public clickPopUp = false;
  language = 'ar';
  accountRef: Subscription;
  shwp:boolean = false;
  public night:boolean = true;

  constructor(public commonservice: CommonService, public auth: AuthService, private router: Router,
              private renderer: Renderer2, private translate: TranslateService, private userService: UserService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.clickPopUp =  false;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  hidemenu() {
    this.clickPopUp = false;
  }

  navbaropen() {
    if (this.commonservice.navPopUp) {
      this.commonservice.navPopUp = false;
    } else {
      this.commonservice.navPopUp = true;
    }
  }
  
  logOut() {
    this.auth.ngUnsubscribe.next();
    this.auth.ngUnsubscribe.complete();
    this.auth.signOut();
    localStorage.removeItem('language');
  }


  ngOnDestroy(): void {
    if (this.accountRef) {
      this.accountRef.unsubscribe();
    }
  }

  nightMode(){
    this.night = !this.night;
    if(!this.night){
    this.renderer.addClass(document.body, 'day');
    this.userService.night = false;

    }
    else{
      this.renderer.removeClass(document.body, 'day');
      this.userService.night = true;

    }
  }
}
