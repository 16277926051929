import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../core/api.service';
import { CommonService } from '../core/common.service';
import { Globals } from '../core/globals';
import { Router } from '@angular/router';
import { UserService, UserPreferenceType } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { MovingAverage } from '../models/movingAverage';
import { ActivatedRoute } from "@angular/router";
@Component({
    selector: 'app-scanners',
    templateUrl: './scanners.component.html',
    styleUrls: ['./scanners.component.scss']
})
export class ScannersComponent implements OnInit {
    //@Input() getPageIdFromChilds: number;
    activeBtn = 1;
    headerLabel = "generic.rsi_scanner";
    style1 = {
        //paddingTop: '20px',
        //backgroundColor: "green",
      }
      myBoundProperty: number;
    constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, public commonservice: CommonService,
        public globals: Globals, private userService: UserService, private auth: AuthService) { }

    ngOnInit() { 
        this.commonservice.footerShow = false;
        // console.log(this.route.snapshot.params.pageID,"mkbmc")
    }
    changeComponent(activeButton, headerLabel){
        this.activeBtn = activeButton;
        this.headerLabel = headerLabel;
    }
    updateValue(event){
        this.activeBtn = event;
    }
}