import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { CommonService } from 'src/app/core/common.service';
import { Payment } from 'src/app/models/paymentInfo';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit, OnDestroy {

  paymentRef;
  payments: Array<Payment> = [];
  constructor(private auth: AuthService, public commonservice: CommonService) { }

  ngOnInit() {
    this.payments = [];
    this.paymentRef = this.auth.getPaymentHistory().subscribe(payments => {
      payments.map(p => {
        const data = p.payload.val();

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < Object.keys(data).length; index++) {
          const element = Object.keys(data)[index];
          const obj = data[element];
          if (obj.cratedOn > 0 && obj.Status > 0) {
            this.payments.push(obj);
          }
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.paymentRef) {
      this.paymentRef.unsubscribe();
    }
  }
}
