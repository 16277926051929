import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {
  public popUpbox: boolean = false;

  constructor(public commonService: CommonService, ) { 
    this.commonService.walletName = 'Positions';
  }

  ngOnInit() {
   
  }


}
