import { Component, OnInit, OnDestroy, Input, ComponentRef, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../core/api.service';
import { CommonService } from '../core/common.service';
import { Globals } from '../core/globals';
import { Router } from '@angular/router';
import { UserService, UserPreferenceType } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { MovingAverageCrossover } from '../models/movingAverageCrossover';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
@Component({
  selector: 'app-macrossover',
  templateUrl: './macrossover.component.html',
  styleUrls: ['./macrossover.component.scss']
})
export class MacrossoverComponent implements OnInit, OnDestroy {
  @Output() setPageIdForParent = new EventEmitter<number>();
  @Input() customStyle: {};
  ref:ComponentRef<any>;
  maStats: Array<MovingAverageCrossover> = [];
  parsedData: Array<MovingAverageCrossover> = [];
  listToWatch: Array<string> = [];
  isWatchlistActive = false;
  maType = 'h4';
  orderByField = 'pm';
  reverseSort = true;
  selectedPair = 'BTC';
  maRef: Subscription;
  isRefreshing: boolean = false;
  showWatchlistError: boolean = false;
  tdata=[
    ['d7_26','7_26dist'],
    ['p7_26','7_26percent'],
    ['d7_100','7_100dist'],
    ['p7_100','7_100percent'],
    ['d20_50','20_50dist'],
    ['p20_50','20_50percent'],
    ['d20_100','20_100dist'],
    ['p20_100','20_100percent'],
    ['d7_200','7_200dist'],
    ['p7_200','7_200percent'],
    ['d20_200','20_200dist'],
    ['p20_200','20_200percent'],
  ];
  constructor(private apiService: ApiService, private router: Router, public commonservice: CommonService,
    public globals: Globals, private userService: UserService, private auth: AuthService, private modalService: NgbModal) {
    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    this.commonservice.footerShow = false;
    $(document).ready(() => {
      $(document).on("click", ".clone .fixstarmcross,.clone .fixstarmcrossleft", (e) => {
        let $id = e.target.id;
        this.addToWatchlistmcross($id);
      })
    })
    const this$ = this;
    // this.maRef = this.auth.rsiScannerSettings$.subscribe({
    //   next(settings) {
    //     if (settings) {
    //       this$.orderByField = settings.sortBy;
    //       this$.reverseSort = settings.reverseSort;
    //       this$.rsiType = settings.type;
    //     }
    //   }
    // });

    this.auth.accountSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.selectedPair = settings.pairs;

          if (this$.isWatchlistActive !== settings.isWatchlistActive) {
            if (!this$.isWatchlistActive) {
              this$.maStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            } else {
              this$.maStats = this$.parsedData;
            }
          }

          this$.isWatchlistActive = settings.isWatchlistActive;

          if (settings.watchlist) {
            this$.listToWatch = settings.watchlist;
            if (this$.isWatchlistActive) {
              this$.maStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            }
          }
        }
      }
    });
    setTimeout(() => {
      this.getMovingAveragesCrossover();
    }, 2000)
  }

  ngOnDestroy(): void {
    if (this.maRef) {
      this.maRef.unsubscribe();
    }
  }
  refreshFilters() {
    this.isRefreshing = true;
    this.isWatchlistActive = false;
    this.watchlistPressedAll();
    this.maType = 'h4';
    this.orderByField = 'pm';
    this.reverseSort = true;
    this.selectedPair = 'BTC';
    setTimeout(() => { this.isRefreshing = false }, 300)
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  openPopUp($event, NgbdModalContent) {
    if ($event.target.tagName === 'IMG' || $event.target.tagName === 'img') {
      this.modalService.open(NgbdModalContent, { windowClass: 'showpopup' });
    }
  }
  public getMovingAveragesCrossover() {
    this.apiService.getMovingAverageCrossover().subscribe((data: string) => {
      const atobString = atob(data);
      const decodedString = this.globals.encryptXor(atobString);
      this.parsedData = JSON.parse(decodedString);

      if (this.isWatchlistActive) {
        this.maStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      } else {
        this.maStats = this.parsedData;
      }
      if(this.maStats.length == 0){
        this.showWatchlistError = true;
      }
        $(document).ready(() => {
          $(".clone").remove();
          $(".main-table").clone(true).appendTo('#table-scroll').addClass('clone');
        });
    });
  }

  public isWatched = (coin) => {
    return this.listToWatch.includes(coin);
  }

  addToWatchlistmcross(coin) {
    if (this.isWatched(coin)) {
      this.listToWatch = this.listToWatch.filter((ele) => {
        return ele !== coin;
      });

      if (this.isWatchlistActive) {
        this.maStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      }
    } else {
      this.listToWatch.push(coin);
    }

    this.userService.addToWatchlist(this.listToWatch);
    if (this.isWatchlistActive) {
      this.maStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    }
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  watchlistPressedAll() {
    this.maStats = this.parsedData;
    this.isWatchlistActive = false;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    this.showWatchlistError = false;
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  watchlistPressedStar() {
    this.maStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    this.isWatchlistActive = true;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if(this.maStats.length == 0){
      this.showWatchlistError = true;
    }else{
      this.showWatchlistError = false;
    }
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  watchlistPressed() {
    if (!this.isWatchlistActive) {
      this.maStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      this.isWatchlistActive = true;
    } else {
      this.maStats = this.parsedData;
      this.isWatchlistActive = false;
    }

    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  mobileClick(item) {
    item.isExpanded = !item.isExpanded;
  }

  public maClass = value => {
    if (value >= 0) {
      return 'txt_green';
    } else {
      return 'txt_pink';
    }
  }

  public peakMoveClass = value => {
    if (value > 0) {
      return 'greenpm';
    } else if (value < 0) {
      return 'redpm';
    }
  }

  public nameClass = (value) => {
    if (value === 2) {
      return 'green';
    } else if (value === 3) {
      return 'dgreen';
    } else if (value === -2) {
      return 'pink';
    } else if (value === -3) {
      return 'red';
    } else {
      return 'gray';
    }
  }

  sortByField(field) {
    this.orderByField = field;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.MaScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.MaScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  secondLevelSort(column) {
    this.orderByField = this.maType + '.' + column;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.MaScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.MaScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  changePair(value) {
    this.selectedPair = value;
    this.userService.updateAccoutSettingsField(value, 'pairs');
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  changeType(value) {
    this.maType = value;
    this.userService.updateField(value, 'type', UserPreferenceType.MaScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }
  changeComponent(value){
    this.setPageIdForParent.emit(value)
  }
  checkClass(index){
    return index==0?"longLengthLoader":index==1||index==11||index==12||index==13?"MediumLengthLoader":"smallLengthLoader";
  }
}
