import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletePopupComponent } from '../blog/delete-popup/delete-popup.component';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { map, publishReplay, refCount, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  client;
  mongodb;
  firebaseBlogs;
  articles: Observable<any> | any;
  itemsPerPage = 9;

  constructor(private auth: AuthService, private modalService: NgbModal, public fdb: AngularFireDatabase,
              private firestore: AngularFirestore, private userService: UserService) {
    this.firebaseBlogs = this.fdb.database.ref('/blogs');

    this.articles = fdb.list('blogs').valueChanges().subscribe(articles => {
      // console.log(articles);
      this.articles = articles;
    });
  }

  deleteDialog(titleMessage) {
    const modalRef = this.modalService.open(DeletePopupComponent);
    modalRef.componentInstance.titleMessage = titleMessage;

    return modalRef.result;
  }

  getArticles(category, currentUser) {

    if (category.length > 0) {
      return this.firestore.collection('blogs',
      ref => ref.where(`categories.${category[0]}`, '==', true)
      .where('packages', 'array-contains', currentUser.packageId).limit(this.itemsPerPage).orderBy('createdOn', 'desc'))
      .snapshotChanges();
    } else {
      return this.firestore.collection('blogs',
      ref => ref.where('packages', 'array-contains', currentUser.packageId)
      .limit(this.itemsPerPage).orderBy('createdOn', 'desc')).snapshotChanges();
    }
  }

  getPreviousPageArticles(category, currentUser, startAfter, endBefore) {
    if (category.length > 0) {
      return this.firestore.collection('blogs',
      ref => ref.where(`categories.${category[0]}`, '==', true)
      .where('packages', 'array-contains', currentUser.packageId).orderBy('createdOn', 'desc')
      .startAt(startAfter).endBefore(endBefore).limit(this.itemsPerPage)).get();
    } else {
      return this.firestore.collection('blogs',
      ref => ref.where('packages', 'array-contains', currentUser.packageId)
      .orderBy('createdOn', 'desc').startAt(startAfter).endBefore(endBefore).limit(this.itemsPerPage)).get();
    }
  }

  getNextPageArticles(category, currentUser, startAfter) {
    if (category.length > 0) {
      return this.firestore.collection('blogs',
      ref => ref.where(`categories.${category[0]}`, '==', true)
      .where('packages', 'array-contains', currentUser.packageId).limit(this.itemsPerPage)
      .orderBy('createdOn', 'desc').startAfter(startAfter)).get();
    } else {
      return this.firestore.collection('blogs',
      ref => ref.where('packages', 'array-contains', currentUser.packageId).limit(this.itemsPerPage)
      .orderBy('createdOn', 'desc').startAfter(startAfter)).get();
    }
  }

  getCategories() {
    const categories = this.fdb.database.ref('/blog_categories').once('value');
    return categories;
    // return this.fdb.list('/blog_categories').snapshotChanges().pipe(
    //   map(snapshot => snapshot),
    //   publishReplay(1),
    //   refCount());
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  createArticle(blog) {
    blog.createdOn = this.timestamp;
    return this.firestore.collection('blogs').add(blog);
  }

  async addBlog(blog) {
    this.firebaseBlogs.push(blog).then(() => {
      // console.log('article added');
      return true;
    });
  }

  async updateBlog(blog, blogId, updateFeaturedImage) {
    if (updateFeaturedImage) {
       this.firestore.doc('blogs/' + blogId).update(blog);
    } else {
      // console.log(blog.categories);
      // console.log(cats);

      this.firestore.doc('blogs/' + blogId)
      .update({ categories: blog.categories, content: blog.content, packages: blog.packages, title: blog.title });
    }

    return true;
  }

  getBlogs(category, currentUser): Observable<any> {
    // return this.fdb.database.ref('/blogs').once('value');
    if (category.length > 0) {
      this.fdb.list('/blogs', ref => {
        const q = ref.orderByChild('categories').equalTo(category);
        const values = [];
        q.once('value', snap => {
          // console.log(snap.val());
          values.push(snap.val());
        });

        return q;
      });
      // return this.fdb.list('/blogs').snapshotChanges().pipe(
      //   map(blog => blog.filter(element => element.payload.val()['categories'].includes(category[0])
      //     && element.payload.val()['packages'].includes(currentUser.packageId))),
      //   // tap(blog => blog),
      //   publishReplay(1),
      //   refCount()
      // );
    } else {
      return this.fdb.list('/blogs').snapshotChanges().pipe(
        map(blog => blog.filter(element => element.payload.val()['packages'].includes(currentUser.packageId))),
        publishReplay(1),
        refCount());
    }
  }

  getBlogCategories(): Observable<any> {
    return this.fdb.list('/blog_categories').snapshotChanges().pipe(
      map(snapshot => snapshot),
      publishReplay(1),
      refCount());
  }

  getBlogById(blogId) {
    return this.firestore.doc(`blogs/${blogId}`).valueChanges();
    // return this.fdb.database.ref('blogs').child(blogId);
  }

  deleteBlog(blogId) {
    this.firestore.doc('blogs/' + blogId).delete();
  }

  getCategoryId(categoryId) {
    const categories = this.fdb.list('blog_categories');
    console.log(categories);
    return categories;
  }
}
