import { Component } from '@angular/core';
@Component({
  selector: 'app-scanner',
  templateUrl:'./scannerpopup.component.html'
})
export class ScannerPopup{
  constructor() {}
  getHead(text){
      return `<div class="border_width plr10 fs14"><b>${text}</b></div>`;
  }
  getHtmlData(l1, l2, limg){
    let data = '<div class="border_width fs14 pltbr10 dflex colorSpecial">';
      data += '<div class="w15per">';
      data += '<img class="'+(limg?"":"hide")+'" src="../../assets/images/newicons/'+l1+'">';
      data +=  '<div class="'+(limg?"hide":"")+'">'+l1+'</div></div>';
      data +=  '<div class="w80per">'+l2+'</div><div>';
      return data;
  }
}
