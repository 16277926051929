import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  public btn: boolean = true;

  constructor(public commonService: CommonService, ) { this.commonService.walletName = 'Assets';}

  ngOnInit() {
    
  }

}
