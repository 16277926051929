import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlogCategory } from 'src/app/models/blogCategory';
import { BlogLanguage } from 'src/app/models/blogLanguage';
import { CommonService } from 'src/app/core/common.service';
import { BlogService } from 'src/app/core/blog.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { UserService } from 'src/app/core/user.service';





@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public blogCategory: Array<BlogCategory> = [];
  public languageList: Array<BlogLanguage> = [];
  public blogList = [];
  public selectedCategory = [];
  public selectedLanguage;
  public blogCatName:any = [];
  public dt:any;
  public today:any;
  public open: boolean = false;
  
  p = 1;
  total;
  categories = [];
  selectedCategories = [];
  articlesRef;
  public nav: string = "All";
  selectedCategory_length:boolean = false;

  // Data object for listing items
  tableData: any[] = [];

  // Save first document in snapshot of items received
  firstInResponse: any = [];

  // Save last document in snapshot of items received
  lastInResponse: any = [];

  // Keep the array of first document of previous pages
  prev_strt_at: any = [];

  // Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  // Disable next and prev buttons
  disable_next = false;
  disable_prev = false;


  constructor(private router: Router, public commonservice: CommonService, private userService: UserService,
    public blogService: BlogService, public authService: AuthService) {}

  ngOnInit() {
    this.commonservice.footerShow = false;
    this.blogList = [];
    this.getBlogCategories();
    this.getBlogs();
    // this.page.init('blogs', 'year', { reverse: true, prepend: false });

    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
      window.scroll(0, 0);
    }
  }

  getBlogCategories() {
    this.blogService.getCategories().then(snapshot => {
      snapshot.forEach(snap => {
        const category = { "id": snap.key, "value": snap.val() };
        this.categories.push(category);
      });
      console.log('categories',this.categories);
      
    });
  }

  getBlogs() {
    const filter = this.selectedCategories;
    this.authService.getUser().then(user => {
      const userData = this.userService.currentUserCollection(user.uid);
      userData.valueChanges().subscribe(currentuser => {
        // console.log(currentuser);
        this.articlesRef = this.blogService.getArticles(filter, currentuser).subscribe(data => {
          this.blogList = [];

          if (!data.length) {
            console.log('No Data Available');
            return false;
          }
          this.firstInResponse = data[0].payload.doc;
          this.lastInResponse = data[data.length - 1].payload.doc;

          this.blogList = data.map(e => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data()
            };
          });
          console.log('list data', this.blogList);

          // Initialize values
          this.prev_strt_at = [];
          this.pagination_clicked_count = 0;
          this.disable_next = false;
          this.disable_prev = false;

          // Push first item to use for Previous action
          this.push_prev_startAt(this.firstInResponse);
        });
      });
    });
  }

  nextPage() {
    const filter = this.selectedCategories;
    this.disable_prev = false;
    this.authService.getUser().then(user => {
      const userData = this.userService.currentUserCollection(user.uid);
      userData.valueChanges().subscribe(currentuser => {
        // console.log(currentuser);

        this.articlesRef = this.blogService.getNextPageArticles(filter, currentuser, this.lastInResponse).subscribe(response => {
          if (!response.docs.length) {
            this.disable_next = true;
            this.disable_prev = false;
            return;
          }

          this.firstInResponse = response.docs[0];
          this.lastInResponse = response.docs[response.docs.length - 1];

          this.blogList = [];
          if (!response.docs.length) {
            console.log('No Data Available');
            return false;
          }
          for (const item of response.docs) {
            this.blogList.push(item.data());
          }

          this.pagination_clicked_count++;
          this.push_prev_startAt(this.firstInResponse);
          this.disable_next = false;
        });
      });
    });
  }

  prevPage() {
    const filter = this.selectedCategories;
    this.disable_next = true;
    this.authService.getUser().then(user => {
      const userData = this.userService.currentUserCollection(user.uid);
      userData.valueChanges().subscribe(currentuser => {
        // console.log(currentuser);
        this.articlesRef = this.blogService.getPreviousPageArticles(filter, currentuser, this.get_prev_startAt(), this.firstInResponse)
        .subscribe(response => {

          this.firstInResponse = response.docs[0];
          this.lastInResponse = response.docs[response.docs.length - 1];

          this.blogList = [];
          if (!response.docs.length) {
            console.log('No Data Available');
            return false;
          }
          for (const item of response.docs) {
            this.blogList.push(item.data());
          }
         
          
          // Maintaing page no.
          this.pagination_clicked_count--;

          // Pop not required value in array
          this.pop_prev_startAt(this.firstInResponse);

          // Enable buttons again
          this.disable_prev = false;
          this.disable_next = false;
        });
      });
    });
  }

  // Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  // Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id === element.data().id) {
        element = null;
      }
    });
  }

  // Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1)) {
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    }

    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  getCategoryName(categoryId) {
    const categoryNames = [];

    if (!categoryId) {
      return;
    }

    Object.keys(categoryId).forEach((key, index) => {
      this.categories.forEach((element) => {
        if (element.id === key) {
          categoryNames.push(element.value);
        }
      });
    });
   
    return this.blogCatName = categoryNames;
  

  }

  applyFilter(value) {
    // console.log('filter applied: ', value);
    this.nav = value;
    this.open = false;
    this.selectedCategories = [];
    if (value !== 'All') {
      this.selectedCategories.push(value);
    
    this.selectedCategory = this.categories.filter(x => x.id == this.nav)[0];
    this.selectedCategory_length = true;
    }else{
      this.selectedCategory = [];
      this.selectedCategory_length = false;
    }
    
    console.log(this.selectedCategory);
    
    this.getBlogs();
  }

  pageChanged(page) {
    // console.log(page);
    this.p = page;
  }

  editBlog(blog) {
    // console.log('editing blog ', blog);
    this.router.navigate(['/blog-edit'], { queryParams: { id: blog.id.toString() } });
  }

  async deleteBlog(blog) {
    // console.log('deleting blog ', blog);
    const result = await this.blogService.deleteDialog('Are you sure you want to delete this article?');
    // console.log(result);

    if (result) {
      await this.blogService.deleteBlog(blog.id);
      this.blogList = this.blogList.filter(b => b !== blog);
      this.total = this.total - 1;
    }
  }
  callme(chptrdate){
    this.dt=chptrdate;
     console.log("ch",chptrdate);
    // console.log("today",this.today.getDate());
    // console.log(new Date(this.dt));
    let res = this.dt.split(" ");
    // console.log(res);
    let dateyear = res[0].split("-");
    // console.log(dateyear);
    // console.log(dateyear[2]);
    let d='';
    let date = dateyear[2];
    if(date==this.today.getDate()){
     d= "Today";
    }else if(date==this.today.getDate()-1){
     d= "Yesterday";
    }else if((date!=this.today.getDate()) && (date!=this.today.getDate()-1)){
     d= res[0];
    // console.log(res[0]);
    }
    
    return d;
    }
}
