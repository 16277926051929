import { Component, OnInit } from '@angular/core';
import { CommonService } from '../core/common.service';

@Component({
  selector: 'app-exchanges',
  templateUrl: './exchanges.component.html',
  styleUrls: ['./exchanges.component.scss']
})
export class ExchangesComponent implements OnInit {

  public success: boolean = false;
  public open:boolean = false;

  constructor(public commonservice: CommonService) { }

  ngOnInit() {
    this.commonservice.footerShow = false;
   
  }

}
