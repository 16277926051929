import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterby'
})
export class FilterbyPipe implements PipeTransform {

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
        return items;
    }

    if (filter === 'ALL') {
      return items;
    }

    // filter items array, items which match and return true will be
    return items.filter(item => item.n.endsWith(filter));
  }
}
