import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common.service';

@Component({
  selector: 'app-trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.scss']
})
export class TradeHistoryComponent implements OnInit {

  constructor(public commonService: CommonService, ) {
    this.commonService.walletName = 'Trade History';
   }

  ngOnInit() {
  
  }

}
