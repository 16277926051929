import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public navPopUp = false;
  public getCurrentUrl: string;
  public isMobile = false;
  public isTablet = false;
  public isheadershow = false;
  public isverificationClose = false;
  public isnightmode = true;
  public navShow:boolean = false; 
  public msg: any;
  public workError:boolean =false;
  public workSuccess: boolean = false;
  public subPayment: number;
  public subPayTitle: string;
  public duration: number = 1;
  public wallet: string = 'positions';
  public walletName: string = 'Assets';
  public currentPckId: string;
  public currentPckName: string = '';
  public footerShow: boolean = true;
  
  constructor() { }

  success(){
    this.workSuccess = true;
    setTimeout(() => {
    this.workSuccess = false;
   }, 5000);
  }
    
  error(){
    this.workError = true;
    setTimeout(() => {
      this.workError = false;
       }, 5000);
  }


}
