import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common.service';
import { AuthService } from '../../core/auth.service';


@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss']
})
export class HeadComponent implements OnInit {
  public shwp:boolean = false;

  constructor(public commonservice: CommonService,  public auth: AuthService,) { }

  logOut() {
    this.auth.userLogin = false;
    this.auth.ngUnsubscribe.next();
    this.auth.ngUnsubscribe.complete();
    this.auth.signOut();
    localStorage.removeItem('language');
  }

  ngOnInit() {
  }

}
