import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth.service";
import { CommonService } from "../../core/common.service";
import { UserService } from "../../core/user.service";

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.scss"]
})
export class CreditCardComponent implements OnInit {
  public open: boolean = false;
  public selectCountry: String = "Please select country";

  constructor(
    public authService: AuthService,
    public commonService: CommonService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.authService.paymtName = "Credit Card";
    this.handleSubmit();
  }

  handleSubmit = () => {
    var stripe_url = "https://api.stripe.com/v1/";
    var secret_key = "pk_test_sVYJ2G1wNabF5xfAcIy9ibSC";
    var cardDetails = {
      "card[number]": 4111111111111111,
      "card[exp_month]": 12,
      "card[exp_year]": 22,
      "card[cvc]": 123
    };
    var formBody = [];
    for (var property in cardDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cardDetails[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    let finalStringify = formBody.join("&");
    return fetch(stripe_url + "tokens", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + secret_key
      },
      body: finalStringify
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.card) {
          console.log("My name lahore", data);
        } else {
          console.log("card error");
        }
      });
  };
  sCountry(a: any) {
    this.selectCountry = a;
    this.open = !this.open;
  }
}
