import { Component, OnInit, Renderer2  } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { CommonService } from '../../core/common.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-stater',
  templateUrl: './stater.component.html',
  styleUrls: ['./stater.component.scss']
})
export class StaterComponent implements OnInit {
  public checkType: string ;
  public user$: any;



  public dropbox = false;
  public selectsubscribe = 'halfmonth';
  public createPaymentsRef;
  payments$;
  filteredPackages: any;
  packages: any;
  packageIds: Array<string> = [];
  selectedIndex;
  public packagesRef;
  /// Active filter rules
  filters = {};
  btnEnble: string;
  public dollar: any;
  public yrAmt: any;
  public packRes:boolean = false;





  constructor(
    public auth: AuthService,
    public commonService: CommonService,
    public render: Renderer2

  ) { }


  checkField(a) {
    this.checkType = a;
    
    if(this.checkType == 'checked'){
      this.commonService.subPayment = this.packages[0].package_info.price_1month;
      this.commonService.subPayTitle = 'Starter monthly';
      this.btnEnble = '1';
      this.commonService.duration = 1;
    }
    if(this.checkType == 'checked1'){
      this.commonService.subPayment = this.packages[0].package_info.price_1year;
      this.commonService.subPayTitle = 'Starter yearly';
      this.btnEnble = '1';
      this.commonService.duration = 12;
    }
    if(this.checkType == 'checked2'){
      this.commonService.subPayment = this.packages[1].package_info.price_1month;
      this.commonService.subPayTitle = 'Pro monthly';
      this.btnEnble = '2';
      this.commonService.duration = 1;
    }
    if(this.checkType == 'checked3'){
      this.commonService.subPayment = this.packages[1].package_info.price_1year;
      this.commonService.subPayTitle = 'Pro yearly';
      this.btnEnble = '2';
      this.commonService.duration = 12;
    }
  }

  ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.user$ = user;
    });

    this.pack();
    
  }


  async pack() {
    await this.auth.getPackages().subscribe(packages => {
      // console.log("for packages", packages);
      this.packRes = true;
      const language = this.auth.getLanguage();
      this.packages =  packages.map(p => p.payload.val());
      this.filterExact('language', language);
     
    //  this is for split Stater amount to two veriables
      let str = this.packages[0].package_info.price_1year;
      let str1 = str / 12;
      this.dollar = str1.toFixed(2).toString().split('.');

      //  this is for split Pro amount to two veriables
      let str2 = this.packages[1].package_info.price_1year;
      let str3 = str2 / 12;
      this.yrAmt = str3.toFixed(2).toString().split('.');

      
      
      
      for (const singlePckg of packages) {
        if (singlePckg.payload.val().language === language) {
          this.packageIds.push(singlePckg.key);
        }
      }
     
    });
  }





  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }
  private applyFilters() {
    this.packages = _.filter(this.packages, _.conforms(this.filters));
  }
  popUp() {
    this.auth.pymtOpn = true;
    if(!this.commonService.isMobile){
    this.render.addClass(document.body, "bg-disabled");
  }
}
  popUpClose() {
    this.render.removeClass(document.body, "bg-disabled");
    this.auth.pymtOpn = false;
    }



}
