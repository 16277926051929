import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../core/common.service';
import { Volume } from '../models/volume';
import { VolumeConfig, TradingRoomConfig } from '../models/userConfig';
import { ApiService } from '../core/api.service';
import { Globals } from '../core/globals';
import { AuthService } from '../core/auth.service';
import { UserService, UserPreferenceType } from '../core/user.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
@Component({
  selector: 'app-volumescanner',
  templateUrl: './volumescanner.component.html',
  styleUrls: ['./volumescanner.component.scss']
})
export class VolumescannerComponent implements OnInit, OnDestroy {
  @Input() customStyle: {};
  @Output() setPageIdForParent = new EventEmitter<number>();
  public isClicked = false;
  public VolumeFiboVal = 'Volume';
  volumeStats: Array<Volume> = [];
  parsedData: Array<Volume> = [];
  listToWatch: Array<string> = [];
  isWatchlistActive = false;
  slider = 'trend';
  orderByField = 'pm';
  reverseSort = true;
  selectedPair = 'BTC';
  state = 'inactive';
  volumeRef: Subscription;
  isRefreshing: boolean = false;
  showWatchlistError: boolean = false;
  constructor(private apiService: ApiService, public globals: Globals, private router: Router,
    public commonservice: CommonService, private auth: AuthService, private modalService: NgbModal, private userService: UserService) {
    this.commonservice.getCurrentUrl = this.router.url;
    if (this.commonservice.isMobile) {
      this.commonservice.navPopUp = true;
    }
  }

  ngOnInit() {
    $(document).ready(() => {
      $(document).on("click", ".clone .fixstarvol", (e) => {
        let $id = e.target.id;
        this.addToWatchlistvolu($id);
      })
    })
    const this$ = this;
    this.volumeRef = this.auth.volumeScannerSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.orderByField = settings.sortBy;
          this$.reverseSort = settings.reverseSort;
          this$.slider = settings.view;
        }
      }
    });

    this.auth.accountSettings$.subscribe({
      next(settings) {
        if (settings) {
          this$.selectedPair = settings.pairs;

          if (this$.isWatchlistActive !== settings.isWatchlistActive) {
            if (!this$.isWatchlistActive) {
              this$.volumeStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            } else {
              this$.volumeStats = this$.parsedData;
            }
          }

          this$.isWatchlistActive = settings.isWatchlistActive;

          if (settings.watchlist) {
            this$.listToWatch = settings.watchlist;
            if (this$.isWatchlistActive) {
              this$.volumeStats = this$.parsedData.filter(element => this$.listToWatch.includes(element.n));
            }
          }
        }
      }
    });
    setTimeout(() => {
      this.getVolume();
    }, 2000)
  }
  refreshFilters() {
    this.isRefreshing = true;
    this.isWatchlistActive = false;
    this.watchlistPressedAll();
    this.orderByField = 'pm';
    this.reverseSort = true;
    this.selectedPair = 'BTC';
    setTimeout(() => { this.isRefreshing = false }, 300)
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }
  public getVolume() {
    this.apiService.getVolume().subscribe((data: string) => {
      const atobString = atob(data);
      const decodedString = this.globals.encryptXor(atobString);
      this.parsedData = JSON.parse(decodedString);

      if (this.isWatchlistActive) {
        this.volumeStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      } else {
        this.volumeStats = this.parsedData;
      }
      if(this.volumeStats.length == 0){
        this.showWatchlistError = true;
      }
      if (this.commonservice.isMobile) {
        $(document).ready(function () {
          $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
        });
      }
    });
  }
  openPopUp($event, NgbdModalContent) {
    if ($event.target.tagName === 'IMG' || $event.target.tagName === 'img') {
      this.modalService.open(NgbdModalContent, { windowClass: 'showpopup' });
    }
  }
  addToWatchlistvolu(coin) {
    if (this.isWatched(coin)) {
      this.listToWatch = this.listToWatch.filter((ele) => {
        return ele !== coin;
      });

      if (this.isWatchlistActive) {
        this.volumeStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      }
    } else {
      this.listToWatch.push(coin);
    }

    this.userService.addToWatchlist(this.listToWatch);
    if (this.isWatchlistActive) {
      this.volumeStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    }
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },10)
  }

  watchlistPressedAll() {
    this.volumeStats = this.parsedData;
    this.isWatchlistActive = false;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    this.showWatchlistError = false;
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }

  watchlistPressedStar() {
    this.volumeStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
    this.isWatchlistActive = true;
    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if(this.volumeStats.length == 0){
      this.showWatchlistError = true;
    }else{
      this.showWatchlistError = false;
    }
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }
  watchlistPressed() {
    if (!this.isWatchlistActive) {
      this.volumeStats = this.parsedData.filter(element => this.listToWatch.includes(element.n));
      this.isWatchlistActive = true;
    } else {
      this.volumeStats = this.parsedData;
      this.isWatchlistActive = false;
    }

    this.userService.updateAccoutSettingsField(this.isWatchlistActive, 'isWatchlistActive');
    if (this.commonservice.isMobile) {
      setTimeout(() => {
        $(".clone").remove();
        $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');
      }, 100)
    }
  }
  public isWatched = (coin) => {
    return this.listToWatch.includes(coin);
  }

  slidChanage(value) {
    this.VolumeFiboVal = value;
    this.userService.updateField(value, 'view', UserPreferenceType.VolumeScanner);
  }

  mobileClick(item) {
    item.isExpanded = !item.isExpanded;
  }

  changePair(value) {
    this.selectedPair = value;
    this.userService.updateAccoutSettingsField(value, 'pairs');
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }

  sortByField(field) {
    this.orderByField = field;
    this.reverseSort = !this.reverseSort;

    this.userService.updateField(this.orderByField, 'sortBy', UserPreferenceType.VolumeScanner);
    this.userService.updateField(this.reverseSort, 'reverseSort', UserPreferenceType.VolumeScanner);
    setTimeout(()=>{
      $(".clone").remove();
      $(".main-table").clone(true, true).appendTo('#table-scroll').addClass('clone');  
    },100)
  }

  public volumeClass = value => {
    if (value >= 0) {
      return 'txt_green';
    } else {
      return 'txt_pink';
    }
  }
  public checkOval = value => {
    if (value >= 23.6 && value <= 50) {
      return 'gradTealOval';
    } else if (value > 50 && value <= 78.6) {
      return 'gradPinkOval';
    } else {
      return 'gradnullOval';
    }
  }

  public peakMoveClass = value => {
    if (value > 0) {
      return 'txt_green';
    } else if (value < 0) {
      return 'txt_pink';
    }
  }
  ngOnDestroy(): void {
    if (this.volumeRef) {
      this.volumeRef.unsubscribe();
    }
  }
  changeComponent(value){
    this.setPageIdForParent.emit(value)
  }
  checkClass(index){
    return index==0?"longLengthLoader":index==1||index==8||index==9?"MediumLengthLoader":"smallLengthLoader";
  }
}
