import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from "@angular/router";
import { CommonService } from '../core/common.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  errorMessage = '';
  successMessage = '';
  public registerProcess = false;
  // Username Check Process Variable
  public usernameProcess = false;
  public usernameNotExist = false;
  public usernameExist = false;

  // Email Check Process Variable
  public emailProcess = false;
  public emailNotExist = false;
  public emailExist = false;

  // password Check Process Variable
  public passwordProcess = false;
  public passwordLength = false;
  public passwordStrong = false;

  // terms and conditions
  public registerBtnDisabled:boolean = true;
  public signupsuccess = false;
  public signuperror = false;

  public usernameValidationMessage = '';
  public shwp = false;
  public referral:boolean = false;
  public showP:any = false;
  pswdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'; 

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder,
    public commanService: CommonService, private toastr: ToastrService,private route: ActivatedRoute, private translate: TranslateService, private modalService: NgbModal) { }

  // referral code
  showHide(){
    this.referral = !this.referral;
  }

  
  ngOnInit() {
    this.commanService.footerShow = true;
    this.registerForm = new FormGroup({
      username: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3)])),
      email: new FormControl(this.route.snapshot.params.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      
     
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      referral: new FormControl(''),
      referral_code: new FormControl(''),
    });

    this.authService.getClosestLanguage();
  }

  onBlurMethodpassword(){
    const password = this.registerForm.get('password').value;
    console.log(password);
    
    if (password.length >= 3) {
      this.passwordProcess = true;
      this.passwordLength = false;
      this.passwordStrong = false;
      if (password.length >= 8){
        this.passwordProcess = false;
        this.passwordStrong = true;

      }
      else {
        this.passwordLength = true;
        this.passwordProcess = false;
      }
    }


  }

  onBlurMethodusername() {
    const Username = this.registerForm.get('username').value;

    if (Username.length >= 3) {
      this.usernameProcess = true;
      this.usernameNotExist = false;
      this.usernameExist = false;
      const letters = /^[\u0621-\u064A\u0660-\u0669a-zA-Z\0-9]+$/;
      if (Username.match(letters)) {
        this.authService.checkUsernameExist(Username).then(res => {
          this.usernameProcess = false;
          if (res['uname'] == null) {
            this.usernameNotExist = true;
            this.usernameExist = false;
          } else {
            this.usernameValidationMessage = 'Username already exists.';
            this.usernameExist = true;
            this.usernameNotExist = false;
          }
        }, err => {
          console.log(err);

        });
      } else {
        this.usernameProcess = false;
        this.usernameValidationMessage = 'Please input alphanumeric characters only';
        this.usernameExist = true;
        this.usernameNotExist = false;
      }
    }
  }

  onBlurMethodemail() {
    let email = this.registerForm.get('email').value;
  }

  tryRegister(value) {
    this.registerProcess = true;

    this.authService.register(value, this.authService.getLanguage())
      .then(res => {
        this.registerProcess = false;
        this.errorMessage = this.translate.instant('toastr.register');
        this.signupsuccess = true;
        this.signuperror = false;
        // this.toastr.success(this.translate.instant('toastr.register'),
        //   this.translate.instant('toastr.congrats'), { progressBar: true });
        this.router.navigate(['/subscription']);
      }, err => {
        this.errorMessage = err.message;
        this.signupsuccess = false;
        this.signuperror = true;
        this.registerProcess = false;
        // this.toastr.error(err.message,
        //     this.translate.instant('toastr.ops'), { progressBar: true });
      });
  }

  changeLanguage(value) {
    if (value === '') {
      return;
    }

    this.translate.use(value);
    this.authService.currentLanguage$.next(value);
    localStorage.setItem('language', value);
  }

  openModal(modal) {
    this.modalService.open(modal, { windowClass: 'terms purchase-detail-popup' });
  }

  
}
