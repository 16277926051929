import { Component, OnInit } from '@angular/core';
import { CommonService } from '../core/common.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../core/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public getStartedForm: FormGroup;
  btnLable:string = "Get started now";
  constructor(public commonservice: CommonService, public auth: AuthService,private router: Router, private fb: FormBuilder, public userService: UserService,) {}

  get formCheck() {
    return this.getStartedForm.controls;
  }

  tryGetStartedForm() {
   

    if (!this.getStartedForm.valid) {
    return
    }
    else {
    if (this.commonservice.getCurrentUrl == '/home') {
    this.router.navigate(['/register', { email: this.getStartedForm.get('email').value }]);
    } else {
    this.userService.trySubscrib(this.getStartedForm.value).then(res => {
    if (res) {
    this.btnLable = "Subscribed";
    //this.router.navigate(['/register', { email: this.getStartedForm.get('email').value }]);
    }
    })
    }
    console.log('Home.Component TS try Subscib Data', this.getStartedForm.value);
    
    }
    }

  ngOnInit() {
    

    this.getStartedForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
    });
  }

}
