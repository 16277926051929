import { Component, Renderer2, OnInit } from "@angular/core";
import { CommonService } from "../core/common.service";
import { UserService } from "src/app/core/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { AuthService } from "../core/auth.service";
import { User } from "src/app/models/user";
import { ToastrService } from "ngx-toastr";

import { formControlBinding } from "@angular/forms/src/directives/reactive_directives/form_control_directive";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit {
  public changePassForm: FormGroup;
  public profileUpdateForm: FormGroup;
  user: User;
  email: string;
  public deleteAccount: boolean = false;
  public deleteAccount1: boolean = false;
  fileToUpload: File = null;
  public open: boolean = false;
  public saving: boolean = false;
  public news: boolean = false;
  public loginNoti: boolean = false;
  public pass: string = "";
  public cPass: boolean = false;
  public sProfile: boolean = false;
  public tWay: boolean = false;
  public sPref: boolean = false;
  

  

  constructor(
    private fb: FormBuilder,
    public commonservice: CommonService,
    public authService: AuthService,
    public userService: UserService,
    private toastr: ToastrService,
    public render: Renderer2
  ) {}

  ngOnInit() {
    this.commonservice.footerShow = false;
    this.changePassForm = new FormGroup({
      oldPassword: new FormControl(""),
      newPassword: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(8)])
      )
    });

    this.profileUpdateForm = new FormGroup({
      userName: new FormControl(this.authService.displayName),
      email: new FormControl(this.authService.useremail),
      displayPic: new FormControl("")
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload) {
      const reader = new FileReader();
      reader.onload = e => (this.authService.photoURL = reader.result);

      reader.readAsDataURL(this.fileToUpload);
    }
  }

  tryChangePass() {
    this.cPass = true;
    this.authService.user$.subscribe(user => {
      // console.log('user data', user);
     
      if (user) {
        this.authService
          .changePassword(this.changePassForm.value, user.email)
          .then(
            res => {
              this.commonservice.msg = "Password successfully updated";
              this.commonservice.success();
              this.cPass = false;
            },
            err => {
              this.commonservice.msg = err.message;
              this.commonservice.error();
              this.cPass = false;
            }
          );
      }
      

    });
  }

  tryUpdateProfile() {
    this.sProfile = true;
    this.authService.user$.subscribe(user => {
     
      if (user) {
        // console.log('ts file data from form ', this.profileUpdateForm.value);

        this.authService
          .updateUserName(this.profileUpdateForm.value, this.fileToUpload)
          .then(
            res => {
              this.commonservice.msg = "Profile Update Successfully";
              this.commonservice.success();
              this.sProfile = false;
            },
            err => {
              // console.log('ts file error', err );

              this.commonservice.msg = err.message;
              this.commonservice.error();
              this.sProfile = false;
            }
          );
      }
    });
  }

  tryCloseAccount() {
    this.render.removeClass(document.body, "bg-disabled");
    let changeUserName = this.authService.deleteUser(this.pass);
    this.deleteAccount1 = false;
    }

 

  savePreferences() {
    this.sPref=true;
    this.userService.newsPreferences(this.news);
    this.userService.loginPreferences(this.loginNoti);
    this.commonservice.msg = "New Update and Login Notifications Updated";
    this.commonservice.success();
    
    setTimeout(()=> { this.sPref=false; }, 500);
  }

  step2() {
    this.deleteAccount = false;
    this.deleteAccount1 = true;
  }
  del_popUp() {
    this.deleteAccount = true;
    this.render.addClass(document.body, "bg-disabled");
  }
  del_popUpClose() {
    this.render.removeClass(document.body, "bg-disabled");
    this.deleteAccount = false;
    this.deleteAccount1 = false;
  }
  
}
