import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/common.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss']
})
export class LeftsidebarComponent implements OnInit {

  public isClicked:boolean = false;
  public BTC:boolean = false; 
  user: User;
  userPackage;
  public closed = false;

  constructor(public commonservice: CommonService, private router: Router, public auth: AuthService) {
  }


  // for routing perpose 
  assets(){
    this.BTC = true;
    this.commonservice.navShow = false;
    this.commonservice.wallet = 'assets';
  }
  positions(){
    this.BTC = true;
    this.commonservice.navShow = false;
    this.commonservice.wallet = 'positions';
  }
  orders(){
    this.BTC = true;
    this.commonservice.navShow = false;
    this.commonservice.wallet = 'orders';
  }
  tradeHistory(){
    this.BTC = true;
    this.commonservice.navShow = false;
    this.commonservice.wallet = 'trade-history';
  }



  ngOnInit() {
    this.auth.user$.subscribe(user => this.user = user);
    // console.log(this.user);

    // const userPackage = this.auth.getUserPackage(this.user.packageId);
    // console.log(this.userPackage);
  }

  isActiveMenu(URL) {
    if (this.commonservice.getCurrentUrl === URL) {
      return 'price-action active';
    } else {
      return 'price-action';
    }
  }
  menulist() {
    if (this.isClicked) {
      this.isClicked = false;
    } else {
      this.isClicked = true;
    }
  }
}
