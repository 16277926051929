import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { CommonService } from "../core/common.service";
import {
  Observable,
  of,
  ReplaySubject,
  Subscription,
  merge,
  Subject,
  BehaviorSubject
} from "rxjs";
import {
  map,
  switchMap,
  first,
  takeUntil,
  last,
  publishReplay,
  refCount,
  tap,
  take,
  filter
} from "rxjs/operators";
import { Router } from "@angular/router";
import {
  AccountSettings,
  RsiScannerSettings,
  VolumeScannerSettings,
  PriceActionSettings
} from "../models/useraccountsettings";
import { Payment } from "../models/paymentInfo";
import { User } from "../models/user";
import { auth, database, storage } from "firebase/app";
import { RestService } from "./rest.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "./user.service";

import { splitNsName } from "@angular/compiler";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public firedataUsername;
  public firedataUserData;
  public firedataUserPreferences;
  public emailID;
  public auth: any;
  public error: string;
  public useremail: any;
  public displayName: string;
  public photoURL: any;
  public userLogin: boolean;
  database: database.Database;
  user: User;
  sucess: any;

  public changePassSuccess: boolean = false;
  public changePassError: boolean = false;
  public msg: string;
  public paymt: string = "cc";
  public paymtName: string = "Credit Card";
  public pymtOpn: boolean = false;

  user$: Observable<User>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.getLanguage()
  );

  accountSettings$: ReplaySubject<AccountSettings>;
  priceActionSettings$: ReplaySubject<PriceActionSettings>;
  rsiScannerSettings$: ReplaySubject<RsiScannerSettings>;
  volumeScannerSettings$: ReplaySubject<VolumeScannerSettings>;
  payments$: ReplaySubject<any>;

  accountSettingsSub: Subscription;
  priceActionSettingsSub: Subscription;
  rsiScannerSettignsSub: Subscription;
  volumeScannerSettingsSub: Subscription;
  paymentsSub: Subscription;
  languageSub: Subscription;

  public colorlist = [
    "#13A288",
    "#C9424C",
    "#E00572",
    "#01BF86",
    "#24ABB0",
    "#2076AE",
    "#D09657",
    "#C75161",
    "#7592AE",
    "#9B9B9B",
    "#119B4F",
    "#F5A623",
    "#F18939",
    "#4A90E2",
    "#9A6CFD",
    "#15A2FF",
    "#1E87F0",
    "#33BBFF",
    "#F0506E",
    "#F18939"
  ];

  constructor(
    public afAuth: AngularFireAuth,
    public fdb: AngularFireDatabase,
    private router: Router,
    public commonService: CommonService,

    private restService: RestService,
    private translate: TranslateService
  ) {
    this.firedataUsername = this.fdb.database.ref("/usernames");
    this.firedataUserData = this.fdb.database.ref("/users");
    this.firedataUserPreferences = this.fdb.database.ref("/user_preferences");

    this.accountSettings$ = new ReplaySubject<AccountSettings>(1);
    this.priceActionSettings$ = new ReplaySubject<PriceActionSettings>(1);
    this.rsiScannerSettings$ = new ReplaySubject<RsiScannerSettings>(1);
    this.volumeScannerSettings$ = new ReplaySubject<VolumeScannerSettings>(1);
    this.payments$ = new ReplaySubject<Payment>();

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.userLogin = true;
          // console.log("auth user", user);
          this.useremail = user.email;
          this.displayName = user.displayName;
          var img_ref = storage().ref(
            "profilePicture/" + user.uid + "/" + user.photoURL
          );
          // console.log("img_ref", img_ref);
          this.photoURL = user.photoURL;
          if (this.photoURL && this.photoURL.indexOf("assets/images/defaultuser.png") === -1) {
            img_ref.getDownloadURL().then(url => {
              // console.log("url", url);

              this.photoURL = url;
            });
          }
          // console.log(" Photo url ", this.photoURL);
          if (!this.languageSub) {
            this.languageSub = this.fdb
              .object(`/users/${user.uid}/language`)
              .valueChanges()
              .pipe(
                map(language => {
                  this.currentLanguage$.next(language as string);
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe();
          }

          if (!this.accountSettingsSub) {
            this.accountSettingsSub = this.fdb
              .object(`user_preferences/${user.uid}/account_settings/`)
              .valueChanges()
              .pipe(
                map(settings => {
                  this.accountSettings$.next(settings as AccountSettings);
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe();
          }
          if (!this.priceActionSettingsSub) {
            this.priceActionSettingsSub = this.fdb
              .object(`user_preferences/${user.uid}/price_action_settings/`)
              .valueChanges()
              .pipe(
                map(settings => {
                  this.priceActionSettings$.next(
                    settings as PriceActionSettings
                  );
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe();
          }

          if (!this.rsiScannerSettignsSub) {
            this.rsiScannerSettignsSub = this.fdb
              .object(`user_preferences/${user.uid}/rsi_scanner_settings/`)
              .valueChanges()
              .pipe(
                map(settings => {
                  this.rsiScannerSettings$.next(settings as RsiScannerSettings);
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe();
          }

          if (!this.volumeScannerSettingsSub) {
            this.volumeScannerSettingsSub = this.fdb
              .object(`user_preferences/${user.uid}/volume_scanner_settings/`)
              .valueChanges()
              .pipe(
                map(settings => {
                  this.volumeScannerSettings$.next(
                    settings as VolumeScannerSettings
                  );
                }),
                takeUntil(this.ngUnsubscribe)
              )
              .subscribe();
          }

          // if (!this.paymentsSub) {
          //   this.paymentsSub =
          //     this.fdb.object(`payments/${user.uid}`).valueChanges().pipe(
          //       map(payment => {
          //         // console.log(`payment -> ${payment.payload.val()}`);
          //         if (payment) {
          //           this.payments$.next(payment as Payment);
          //         }
          //       }), takeUntil(this.ngUnsubscribe)
          //     ).subscribe();
          // }

          return this.fdb.object(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  getUser(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async register(value, language) {
    const displayName = value.username;
    window.localStorage.setItem("referralID", value.referral_code);
    const newUser = await auth()
      .createUserWithEmailAndPassword(value.email, value.password)
      .catch(error => {
        throw new Error("The email is already exist");
      });

    if (newUser) {
      await this.afAuth.auth.currentUser.updateProfile({
        displayName,
        photoURL: "http://sommmerce.com/assets/images/defaultuser.png"
      });

      const userId = newUser.user.uid;
      this.firedataUsername.child(value.username).set(userId);
      this.firedataUserData.child(userId).set({
        uid: userId,
        displayName,
        userName: value.username,
        email: value.email,
        createdAt: this.timestamp,
        initial: this.getInitials(value.username),
        language,
        roles: {
          free: true,
          subscriber: false,
          editor: false,
          admin: false
        },
        // expiration: 10000000,
        color: this.colorlist[Math.floor(Math.random() * this.colorlist.length)]
      });

      this.firedataUserPreferences
        .child(userId)
        .child("account_settings")
        .set(new AccountSettings());
      this.firedataUserPreferences
        .child(userId)
        .child("price_action_settings")
        .set(new PriceActionSettings());
      this.firedataUserPreferences
        .child(userId)
        .child("rsi_scanner_settings")
        .set(new RsiScannerSettings());
      this.firedataUserPreferences
        .child(userId)
        .child("volume_scanner_settings")
        .set(new VolumeScannerSettings());

      await this.restService.registerUser(userId, value.email, value.username, value.username); //TODO: Fix referral part
      await this.afAuth.auth.currentUser.sendEmailVerification();
    }

    return;
  }

  get timestamp() {
    return database.ServerValue.TIMESTAMP;
  }

  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) {
      return false;
    }

    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }

    return false;
  }

  canReadFree(user: User): boolean {
    const allowed = ["admin", "editor", "subscriber", "free"];
    return this.checkAuthorization(user, allowed);
  }

  canReadPaid(user: User): boolean {
    if (user) {
      const allowed = ["admin", "editor", "subscriber"];
      const currentDate = new Date();

      const d1 = new Date(user.expiration * 1000);
      return this.checkAuthorization(user, allowed) && d1 > currentDate;
    }
  }

  canEdit(user: User): boolean {
    const allowed = ["admin", "editor"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: User): boolean {
    const allowed = ["admin"];
    return this.checkAuthorization(user, allowed);
  }

  getInitials(name) {
    const parts = name.split(" ");
    let initials = "";
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {});
  }

  checkUsernameExist(userNameId) {
    let username;
    return new Promise((resolve, reject) => {
      this.firedataUsername
        .child(userNameId)
        .once("value", snapshot => {
          username = snapshot.val();
        })
        .then(() => {
          resolve({ uname: username });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async login(value) {
    const credential = await auth()
      .signInWithEmailAndPassword(value.email, value.password)
      .catch(error => {
        console.log(error);
      });

    // if (credential && credential.user) {
    //   // const user = credential.user;
    // }
    this.emailID = value.email;
    return credential;
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate(["/login"]);
    this.userLogin = false;
  }

  async doForgot(value) {
    const resetResponse = await auth()
      .sendPasswordResetEmail(value.email)
      .catch(error => {
        console.log(error);
      });

    return resetResponse;
  }

  // getLanguage() {
  //   return this.currentLanguage$.value;
  // }

  private hasLanguage(): boolean {
    return !!localStorage.getItem("language");
  }

  public getLanguage(): string {
    return localStorage.getItem("language");
  }

  autoSelectLanguage() {
    if (!this.translate.currentLang) {
      if (this.hasLanguage()) {
        // console.log('using cache for language');
        this.translate.use(this.getLanguage());
      } else {
        // console.log('MISSING LANGUAGE PLEASE CHECK!');
      }
    }
  }

  getClosestLanguage() {
    if (!this.translate.currentLang) {
      // use navigator lang if available
      let userLang = navigator.language.split("-")[0];
      userLang = /(es|en)/gi.test(userLang) ? userLang : "en";

      const browserLanguage = this.translate.getBrowserLang();
      if (!browserLanguage) {
        this.translate.use("en");
        return;
      }

      if (browserLanguage === "en") {
        this.translate.use("en");
      } else if (browserLanguage === "ar") {
        this.translate.use("ar");
      } else {
        this.translate.use("en");
      }
    }
  }

 getBlogCategories(): Observable<any> {
    return this.fdb.list('/blog_categories').snapshotChanges().pipe(
      map(snapshot => snapshot),
      publishReplay(1),
      refCount());
  }

  getPackages(): Observable<any> {
    return this.fdb
      .list("/packageManager") .snapshotChanges() .pipe(  map(snapshot => snapshot),
        publishReplay(1),
        refCount()
      );
  }

  getPaymentHistory(): Observable<any> {
    return this.fdb
      .list("/payments")
      .snapshotChanges()
      .pipe(
        map(payment => payment),
        publishReplay(1),
        refCount()
      );
  }

  getPayments(userId): Observable<any> {
    return this.fdb
      .list(`/payments/${userId}`)
      .snapshotChanges()
      .pipe(map(payment => payment));
  }

  getUserPackage(packageId): any {
    this.getPackages().subscribe(pkgs => {
      return pkgs.filter(p => p.key === packageId).map(p => p.payload.val());
    });
  }

  updateUserName(value, file) {
    return new Promise<any>((resolve, reject) => {
      const user = auth().currentUser;
      let email = auth().currentUser.email;
      let str = file.name;
      let temp = str.split(".");
      temp = user.uid + "." + temp[1];

      if (value.email != email) {
        user.updateEmail(value.email).then(
          function() {
            user.updateProfile({ displayName: value.userName, photoURL: temp });
            let storageRef = storage().ref(
              "profilePicture/" + user.uid + "/" + temp
            );
            let task = storageRef.put(file);

            user.sendEmailVerification();
            resolve(
              "Email update successfully and email sent for verification"
            );
          },
          err => {
            reject(err);
          }
        );
      } else {
        user.updateProfile({ displayName: value.userName, photoURL: temp });
        let storageRef = storage().ref(
          "profilePicture/" + user.uid + "/" + temp
        );
        let task = storageRef
          .put(file)

          .then(
            function() {
              resolve("Dispay Name Successfully updated");
              // console.log("service file run else part for display name");
            },
            err => {
              reject(err);
            }
          );
      }
    });
  }

  changePassword(value, email) {
    return new Promise<any>((resolve, reject) => {
      const user = auth().currentUser;
      const credential = auth.EmailAuthProvider.credential(
        email,
        value.oldPassword
      );
      user.reauthenticateWithCredential(credential).then(
        function() {
          user.updatePassword(value.newPassword).then(
            function() {
              resolve(" Password update successfully ");
            },
            err => {
              reject(err);
            }
          );
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteUser(password) {
    let user = this.afAuth.auth.currentUser;
    let email = user.email;
    const credential = auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        user.delete().then(() => {
            this.commonService.msg ="User Deleted";
            this.commonService.success();
            this.router.navigate(["/login"]);
          })
          .catch(error => {
            this.router.navigate(["/login"]);
            this.commonService.msg = error;
            this.commonService.error();
            
          });
      })
      .catch(error => {
        this.commonService.msg = error;
        this.commonService.error();
      });
  }
}
