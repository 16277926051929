import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../core/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from '../../core/blog.service';
import { AuthService } from '../../core/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ms-blog-detail',
   templateUrl: './blog-detail-component.html',
   styleUrls: ['./blog-detail-component.scss'],
   encapsulation: ViewEncapsulation.None
   })

export class BlogDetailComponent implements OnInit {

  blogId;
  blog;
  categories = [];
  public blogCatName:any = [];
  public popupImage: any;

   constructor(public commonservice: CommonService, public translate: TranslateService, private blogService: BlogService,
               private Activatedroute: ActivatedRoute, private router: Router,
               public authService: AuthService, private modalService: NgbModal) {
   }

   ngOnInit() {
     this.commonservice.footerShow =false;
    this.Activatedroute.queryParamMap.subscribe(params => {
      this.blogId = params.get('id') || '';
      this.getBlogData();
      this.getBlogCategories();
    });
   }

  async getBlogData() {
    await this.blogService.getBlogById(this.blogId).subscribe(data => {
      this.blog = data;
      console.log('data', data);
      
    });
  }
  getBlogCategories() {
    this.blogService.getCategories().then(snapshot => {
      snapshot.forEach(snap => {
        const category = { id: snap.key, value: snap.val() };
        this.categories.push(category);
      });
    });
  }
  editBlog(blog) {
    // console.log('editing blog ', blog);
    this.router.navigate(['/blog-edit'], { queryParams: { id: this.blogId } });
  }

  async deleteBlog(blog) {
    // console.log('deleting blog ', blog);
    const result = await this.blogService.deleteDialog('Are you sure you want to delete this article?');
    // console.log(result);

    if (result) {
      await this.blogService.deleteBlog(this.blogId);
      this.router.navigate(['/blog']);
    }
  }

  disableBlog(blog) {
    // console.log('disabling blog ', blog);
  }

  goBack() {
    this.router.navigate(['/blog']);
  }

  imagepopup($event, NgbdModalContent, image) {
    if ($event.target.tagName === 'IMG' || $event.target.tagName === 'img') {
      const modalRef = this.modalService.open(NgbdModalContent, { windowClass: 'image-popup' });
      this.popupImage = { is_image: true, url: image };
    }
    if ($event.target.className === 'pdf-file' || $event.target.className === 'pdf-file' || $event.target.className === 'pdf-text') {
      const modalRef = this.modalService.open(NgbdModalContent, { windowClass: 'image-popup' });
      this.popupImage = { is_image: false, url: image };
    }
  }
  getCategoryName(categoryId) {
    const categoryNames = [];

    if (!categoryId) {
      return;
    }

    Object.keys(categoryId).forEach((key, index) => {
      this.categories.forEach((element) => {
        if (element.id === key) {
          categoryNames.push(element.value);
        }
      });
    });
   
    // console.log('asdfs', categoryNames);
    
    return this.blogCatName = categoryNames;
  

  }
}
